import { useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { toast } from "react-toastify";

const ReportModal = ({ visible, modalClose, onAdded }:{
    visible: boolean,
    modalClose: () => void,
    onAdded : (val:generalObj) => void
}) => {
    const [formData, setFormData] = useState<generalObj>({
        date_from: "",
        date_to: ""
    });

    const submit = async () => {
        if (formData.date_from === "" || formData.date_to === "") return;
        let from_date = new Date(formData.date_from)
        let to_date = new Date(formData.date_to)
        if (to_date.getTime() < from_date.getTime()) {
            toast("Date to cannot be less than Date from");
            return;
        }
        onAdded(formData);
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Generate Report</h4>
                    <div>
                        <InputField label={"Date From"} inputProp={{value:formData.date_from, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => setFormData({...formData, date_from:evt})} containerStyle={{width:"100%", marginBottom:"20px"}} />
                        <InputField label={"Date To"} inputProp={{value:formData.date_to, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => setFormData({...formData, date_to:evt})} containerStyle={{width:"100%", marginBottom:"20px"}} />
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Generate" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => submit()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default ReportModal;