import { useEffect, useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext } from "react-router-dom";
import { createStocking } from "../../action/generalFunc";
import { toast } from "react-toastify";

const UpdateStockingModal = ({ visible, stocking_id, modalClose, onAdded }:{
    visible: boolean,
    stocking_id: generalObj,
    modalClose: () => void,
    onAdded : () => void
}) => {

    const [formData, setFormData] = useState<generalObj>({
        opening_stock: "",
        opening_stock_date: "",
        closing_stock: "",
        closing_stock_date: "",
        ros:"",
        soldinfo:"",
        damages:"",
        comment:""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    useEffect(() => {
        if (!stocking_id.filled) {
            setFormData({
                opening_stock:stocking_id.open_stock,
                opening_stock_date: stocking_id.date_from
            })
            return
        };
        setFormData({
            opening_stock:stocking_id.open_stock.toString(),
            opening_stock_date: stocking_id.date_from,
            closing_stock:stocking_id.close_stock.toString(),
            closing_stock_date: stocking_id.date_to,
            ros:stocking_id.ros,
            soldinfo:stocking_id.quantity_sold,
            damages:stocking_id.damages,
            comment:stocking_id.comments
        })
    }, [stocking_id])

    const updateStocking = async () => {
        if (!account || !account.isAuthenticated || !stocking_id) return;
        if (formData.opening_stock.length === 0) {
            toast("Opening stock is required");
            return;
        }
        if (parseInt(formData.opening_stock) < 0) {
            toast("Opening stock is not a number");
            return;
        }
        if (formData.closing_stock.length === 0) {
            toast("Closing stock is required");
            return;
        }
        if (parseInt(formData.closing_stock) < 0) {
            toast("Closing stock is not a number");
            return;
        }

        if (formData.opening_stock_date === "") {
            toast("Opening stock date is required");
            return;
        }
        if (formData.closing_stock_date === "") {
            toast("Closing stock date is required");
            return;
        }

        if (parseInt(formData.closing_stock) > stocking_id.quantity) {
            toast("Closing stock cannot be greater than SOH");
            return;
        }
        // if (formData.soldinfo === "") {
        //     toast("Quantity sold is required");
        //     return;
        // }

        // if (!parseInt(formData.soldinfo)) {
        //     toast("Quantity sold is not a number");
        //     return;
        // }
        if (formData.ros === "") {
            toast("Rate of sales is required");
            return;
        }
        if (!parseInt(formData.ros)) {
            toast("Rate of sales is not a number");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            stocking_id: stocking_id.id,
            opening_stock: formData.opening_stock,
            opening_stock_date: formData.opening_stock_date,
            closing_stock: formData.closing_stock,
            closing_stock_date: formData.closing_stock_date,
            ros_info: formData.ros,
            damages: formData.damages,
            comment:formData.comment
        };

        let result = await createStocking(account.token, api_data, "PUT");
        if (result) {
            toast(result);
            setTimeout(() => {
                onAdded();
            }, 1500);
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Update Stocking</h4>
                    <div>
                        <div style={{display:'flex', gap:"14px", alignItems:"center"}}>
                            <InputField label={"Opening Stock"} inputProp={{value:formData.opening_stock, placeholder:"Enter opening stock", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("opening_stock", evt)} containerStyle={{width:"50%"}} />
                            <InputField label={"Opening Date"} inputProp={{value:formData.opening_stock_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("opening_stock_date", evt)} containerStyle={{width:"50%"}} />
                        </div>
                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"18px"}}>
                            <InputField label={"Closing Stock"} inputProp={{value:formData.closing_stock, placeholder:"Enter closing stock", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("closing_stock", evt)} containerStyle={{width:"50%"}} />
                            <InputField label={"Closing Date"} inputProp={{value:formData.closing_stock_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("closing_stock_date", evt)} containerStyle={{width:"50%"}} />
                        </div>
                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"18px"}}>
                            <InputField label={`Rate of Sales`} inputProp={{value:formData.ros, placeholder:"Enter rate of sale", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("ros", evt)} containerStyle={{width:"100%"}} />
                        </div>
                        {/* <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"24px"}}>
                            <InputField label={"Quantity Sold"} inputProp={{value:formData.soldinfo, placeholder:"Enter quantity sold", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("soldinfo", evt)} containerStyle={{width:"100%"}} />
                        </div> */}
                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"18px"}}>
                            <InputField label={"Damages"} inputProp={{value:formData.damages, placeholder:"Enter number damage products", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("damages", evt)} containerStyle={{width:"100%"}} />
                        </div>
                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"18px"}}>
                            <InputField label={"Damages Comment"} inputProp={{value:formData.comment}} multiline={true} onTextChange={(evt) => updateFormData("comment", evt)} customInputText={{ width:"100%", height:"100%" }} containerStyle={{width:"100%"}} inputContainerStyle={{height:"50px"}}  />
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"24px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => updateStocking()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default UpdateStockingModal;