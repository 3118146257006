import { useCallback, useEffect, useState } from "react";
import { ButtonFrame, Card, InputField, PageBodyHeader} from "../../component"
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchMessageById, fetchUserList, newMessage, timeStr } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoChevronDown } from "react-icons/io5";
import ReactQuill from 'react-quill';

const AddMessage = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        send_to: "",
        send_to_obj: null,
        subject: "",
        message: ""
    });
    const [showList, setShowList] = useState<boolean>(false);

    const [userList, setUserList] = useState<generalObj[]>([]);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();

    const account = useSelector((state:RootState) => state.account);
    const { id } = useParams();
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        if (id) {
            (async () => {
                let result = await fetchMessageById(account.token, id);
                if (result) {
                    setFormData((m) => ({...m, ...result.message, send_to_obj:result.message.staff}));
                }else {
                    setModalLoading(false);
                    navigate(UrlTypes.STAFF_MESSAGE_LIST);
                }
            })();
        }
        (async () => {
            let result = await fetchUserList(account.token, "staffuser");
            if (result) {
                setUserList(result.data.users);
            }
            setModalLoading(false)
        })();
        
    }, [account, setModalLoading, id, navigate]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const filterObjText = (val:string) => {
        updateFormData("send_to", val);
        setUserList((prevUserList) => {
            return (
                prevUserList.filter((item) => {
                    if (item.username.toLowerCase().includes(val.toLowerCase()) || item.email.toLowerCase().includes(val.toLowerCase())) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
    }

    const toggleShow = useCallback((val:boolean) => {
        setShowList(val);
    }, []);

    const updateToObj = () => {
        updateFormData("send_to", "");
        updateFormData('send_to_obj', null);
        toggleShow(false);
    }


    const addReplyMessage = async () => {
        if (!account || !account.isAuthenticated) return;
        if (!id && (!formData.send_to_obj  || formData.subject.length === 0 || formData.message.length === 0)) {
            toast("All fields are required");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            staff: formData.send_to_obj.id,
            subject: formData.subject,
            message: formData.message
        };

        let method = id ? "PUT" : "POST";
        if (id) {
            api_data.message_id = id
        }

        let result = await newMessage(account.token, method, api_data);
        if (result) {
            toast(result.message)
            navigate(UrlTypes.ADMIN_MESSAGE_LIST);
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Compose Message" desc="Write/reply a Staff" />

            <Card color="#676967" style={{backgroundColor:"#FFF"}} containerCardStyle={{overflow:"auto"}}>
                <>
                    <div style={{display:'flex', gap:"15px", alignItems:"center", borderBottom:"1px solid #B2B5B2"}}>
                        <div className="searchdrop" style={{display:'flex', gap:"24px", alignItems:"center", width:"100%"}}>
                            {id ? (
                                <div className="inputFieldMain" style={{width:"100%"}}>
                                    <ButtonFrame btn_text={formData.staff ? `${formData.staff.username}` : ""} btn_style={{padding:"8px 8px", border:"none", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px", textAlign:"left", width:"100%"}} onPress={() => null} />
                                </div>
                            ):(
                                formData.send_to_obj ? (
                                    <div className="inputFieldMain" style={{width:"100%"}}>
                                        <ButtonFrame btn_text={`${formData.send_to_obj.username}`} btn_style={{padding:"8px 8px", border:"none", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px", textAlign:"left", width:"100%"}} onPress={() => updateToObj()} />
                                    </div>
                                ): (
                                    <>
                                        <InputField inputProp={{value:formData.send_to, placeholder:"To", onFocus:() => toggleShow(true)}} onTextChange={(evt) => filterObjText(evt)} inputContainerStyle={{border:"none"}} containerStyle={{width:"100%"}} isIconRight={<IoChevronDown size={14} color={"#B2B5B2"} />}  />
                                        <div className={`inputDropDown ${showList && userList.length > 0 ? "active" : ""}`} style={{top:"30px"}}>
                                            <ul>
                                                {userList.length > 0 && userList.map((item:generalObj, index:number) => (
                                                    (item.show || item.show === undefined) && (
                                                        <li key={index}>
                                                            <button onClick={() => updateFormData("send_to_obj", item)}>{item.username}</button>
                                                        </li>
                                                    )
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )
                            )}
                        </div>
                    </div>
                    
                    <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"20px", borderBottom:"1px solid #B2B5B2"}}>
                        {id ? (
                            <div className="inputFieldMain" style={{width:"100%"}}>
                                <ButtonFrame btn_text={formData.subject} btn_style={{padding:"8px 8px", border:"none", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px", textAlign:"left", width:"100%"}} onPress={() => null} />
                            </div>
                        ):(
                            <InputField inputProp={{value:formData.subject, placeholder:"Subject"}} onTextChange={(evt) => updateFormData("subject", evt)} inputContainerStyle={{border:"none"}} containerStyle={{width:"100%"}}  />
                        )}
                        {/* <InputField inputProp={{value:"", placeholder:"Subject"}} onTextChange={(evt) => updateFormData("username", evt)} inputContainerStyle={{border:"none"}} containerStyle={{width:"100%"}}  /> */}
                    </div>

                    <div className="" style={{marginTop:"20px"}}>
                        <div className="richText" style={{display:'flex', gap:"24px", alignItems:"flex-start", height:id ? "100px" : "300px"}}>
                            <ReactQuill theme="snow" placeholder={"Write a Message"} value={formData.message} onChange={(val) => updateFormData("message", val)} />
                        </div>
                        {id && formData.messages && (
                            <div className="message__container">
                                {formData.messages.map((item:generalObj) => (
                                    <div key={item.id} className={`messageItem ${account && account.id === item.sender ? "sender" : ""} `}>
                                        <div className="timer">
                                            {`${dateStr(item.created_at)} (${timeStr(item.created_at)})`}
                                        </div>
                                        <div>
                                            <div className="message" dangerouslySetInnerHTML={{__html: item.message}} />
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Send" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addReplyMessage()} />
                        <ButtonFrame btn_text="Back" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>

                </>
            </Card>
        </>
    )
}

export default AddMessage