import { ActionDropDown, ButtonFrame, CardSearch, CustomCheckBox, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchDamageProduct, fetchProductDmgListReport, fetchUserListById, sendDamageEmail } from "../../action/generalFunc";
import { toast } from "react-toastify";

const StaffProductDamage = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [inputChecked, setInputChecked] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const { id, store_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            setModalLoading(true);
            let result = await fetchUserListById(account.token, id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let dmg_result = await fetchDamageProduct(account.token, store_id, id)
                if (!dmg_result) {
                    window.history.back();
                }
                setProductList(dmg_result.products);
                setStoreName(`${dmg_result.store_data.name} - ${dmg_result.store_data.organization}`)
                setVendorName(`${result.data.user.username}`)
                setModalLoading(false)
            })();
        })();
    }, [account, id, store_id, setModalLoading]);

    const changeChecked = (id:number, value:boolean | null = null) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.order_id === id) {
                        item.isChecked = value === null ? !item.isChecked : value;
                    }
                    return item;
                })
            )
        })
    }

    const checkAll = () => {
        let new_check = !inputChecked;
        
        productList.forEach(element => {
            changeChecked(element.order_id, new_check);
        });
        
        setInputChecked(new_check);
    }


    const processExport = (export_type:string) => {
        generateReport(export_type);
    }

    const onSearch = (val:string) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.damaged) || val.toLowerCase().includes(item.returns) || val.toLowerCase().includes(item.return_date)) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const generateReport = async (exportType:string) => {
        if (!exportType || !account || !account.isAuthenticated || !store_id || !id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            store: store_id,
            vendor: id
        };
        
        let result = await fetchProductDmgListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_damage_staff.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    const sendNotification = async (id:number, bulk?:boolean) => {
        if (!account || !account.isAuthenticated) return;

        setModalLoading(true);
        let result = await sendDamageEmail(account.token, {
            product: id
        })

        if (result) {
            if (bulk) {
                return true;
            }else {
                toast(result.message);
                window.location.reload();
            }
        }else {
            if (bulk) {
                return false;
            }else {
                toast(result)
            }
        }
        setModalLoading(false);
    }

    const sendBulkEmail = () => {
        if (!account || !account.isAuthenticated) return;

        let selected_item = productList.filter((item) => item.isChecked && !item.notif && (item.damaged > item.returns));

        if (selected_item.length === 0) {
            toast("No expiry item selected");
            return;
        }
        setModalLoading(true);
        selected_item.forEach(async (eItem, index) => {
            await sendNotification(eItem.order_id, true)

            if (index+1 === selected_item.length) {
                window.location.reload();
                setModalLoading(false);
            }
        })
    }

    return (
        <>
            <PageBodyHeader title="Damages and Returns" desc="List of Damaged and Returned Products">
                <ButtonFrame btn_text="Send notifications" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#EB212F", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => sendBulkEmail()} />      
            </PageBodyHeader>

            <Table table_head={[{
                element: <CustomCheckBox checked={inputChecked} onChange={() => checkAll()} />
            }, "ID", "Product name", "Size", "Quantity Damaged",  "Quantity Returned", "Date", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={
                <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                    <ExportData export_type={(val) => processExport(val)} />
                    <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                </div>
            }
            headerStyle={{marginBottom: "44px"}}
            table_title={`Damage and Return for ${vendorName.toUpperCase()} in ${storeName}`}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>
                                    <CustomCheckBox checked={item.isChecked} onChange={() => changeChecked(item.order_id, !item.isChecked)} />
                                </td>
                                <td>#{index+1}</td>
                                <td>
                                    <div className="contentWithImg">
                                        <span><img src={item.image} alt={item.name} /></span>
                                        <span>{item.name}</span>
                                    </div>
                                </td>
                                <td>{item.size}</td>
                                <td>{item.damaged}</td>
                                <td>{item.returns}</td>
                                <td>{item.return_date.map((rItem:string, index:number) => (
                                    <div key={index}>{rItem}</div>
                                ))}</td>
                                <td>
                                    <div className="edit__delete_action">
                                    <div className={`status__container ${item.notif ? "completed" : "notsent"}`} onClick={() => !item.notif && sendNotification(item.order_id)} style={{width:"150px", padding:"8px", fontWeight:"600",cursor:"pointer"}}>Send notifications</div>
                                        <ActionDropDown>
                                            <ul>
                                                <li>
                                                    <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_DAMAGED_CHILD}${id}/${store_id}/${item.order_id}`)}>View</button>
                                                </li>
                                            </ul>
                                        </ActionDropDown>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            
        </>
    )
}

export default StaffProductDamage;