const ModalParent = ({ visible, children }:{
    visible: boolean,
    children: JSX.Element
}) => {
    return (
        <div className={`ModalParent ${visible ? "active" : ""}`}>
            <div className="ModalContainer">
                {children}
            </div>
        </div>
    )
}

export default ModalParent;