import { useEffect, useState } from "react";
import { AddUnitModal, ButtonFrame, Card, CustomCheckBox, InputDropdown, InputField, PageBodyHeader, UploadField } from "../../component"
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { add_editProduct, fetchCategoryUnitList, fetchProductListById, fetchStoreList, fetchUserList } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddProduct = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        name: "",
        size: "",
        vendor: null,
        store: null,
        unit: null,
        description: "",
        product_image:null,
        product_file:null
    });
    const [productFile, setProductFile] = useState<generalObj|null>(null);


    const [openUnitAdd, setOpenUnitAdd] = useState<boolean>(false);
    const [inputChecked, setInputChecked] = useState<boolean>(false);

    const [vendorList, setVendorList] = useState<generalObj[]>([])
    const [storeList, setStoreList] = useState<generalObj[]>([])
    const [unitList, setUnitList] = useState<generalObj[]>([])

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext()

    const account = useSelector((state:RootState) => state.account);

    const { id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        if (id) {
            (async () => {
                let result = await fetchProductListById(account.token, id);
                if (result) {
                    let store_data:generalObj[] = [];
                    result.data.product.store.forEach((element:generalObj) => {
                        store_data.push({
                            id: element.id,
                            label: `${element.name} - ${element.organization}`,
                            value: element.name
                        })
                    });
                    setFormData({...result.data.product, store: store_data.length > 0 ? store_data : null})
                }else {
                    navigate(UrlTypes.ADMIN_PRODUCT_LIST);
                }
            })();
        }
        (async () => {
            let result = await fetchCategoryUnitList(account.token);
            if (result) {
                let vendor_list:generalObj[] = [];
                let unit_list:generalObj[] = [];
                let str_list:generalObj[] = [];
                result.data.unit.forEach((element:generalObj) => {
                    unit_list.push({
                        id: element.id,
                        label: element.label,
                        value: element.label
                    });
                });
                let vendor_result = await fetchUserList(account.token, "vendor");
                if (vendor_result) {
                    vendor_result.data.users.forEach((element:generalObj) => {
                        vendor_list.push({
                            id: element.id,
                            label: element.username,
                            value: element.email
                        });
                    });
                    let store_result = await fetchStoreList(account.token);
                    if (store_result) {
                        store_result.data.store.forEach((element:generalObj) => {
                            str_list.push({
                                id: element.id,
                                label: `${element.name} - ${element.organization}`,
                                value: element.name
                            });
                        });
                    }
                }
                setUnitList([...unit_list, {
                    id: 0,
                    label: "Add Unit",
                    value: "add_unit"
                }]);
                setVendorList(vendor_list);
                setStoreList(str_list);
            }
            setModalLoading(false);
        })();
    }, [account, id, setModalLoading, navigate]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const updateUnitList = (val:generalObj) => {
        if (val.id === 0) {
            setOpenUnitAdd(true);
            return;
        }
        setFormData((prevFormData) => ({...prevFormData, unit:val}));
    }

    const updateStoreList = (val:generalObj) => {
        let store_list:generalObj[];
        if (formData.store) {
            let isexists = formData.store.filter((item:generalObj) => item.id === val.id);
            store_list = isexists.length > 0 ? formData.store.filter((item:generalObj) => item.id !== val.id) : [...formData.store, val];
        }else {
            store_list = [val];
        }
        setFormData((prevFormData) => ({...prevFormData, store:store_list}));
    }

    const pickImage = (e:generalObj, isDrag:boolean=false) => {
        if ((!isDrag && !e.target.files.length)) return;
        const reader = new FileReader();
        const file = !isDrag ? e.target.files[0] : e;
        if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
            toast("Only image files are allowed");
            return;
        }
        if (Math.floor(file.size / 1000) > 300) {
            toast("Image size is greater than 300kb");
            return;
        }
        setProductFile(file);
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result], {type:"image/png"});
            updateFormData("product_image", blob);
        }
    }

    const checkAll = () => {
        let new_check = !inputChecked;
        
        if (new_check) {
            setFormData((prevFormData) => ({...prevFormData, store:storeList}));
        }else {

            setFormData((prevFormData) => ({...prevFormData, store:null}));
        }
        
        setInputChecked(new_check);
    }

    const addProduct = async (published:boolean) => {
        if (!account) return;
        if (formData.name.length === 0) {
            toast("Product name is required");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            name: formData.name,
            size: formData.size.length > 0 ? formData.size : null,
            unit: formData.unit ? formData.unit.id : null,
            vendor: formData.vendor ? formData.vendor.id : null,
            description: formData.description.length > 0 ? formData.description : null,
            published: published
        };

        if (formData.store) {
            let stores:Array<number> = [];
            formData.store.forEach((element:generalObj) => {
                stores.push(element.id);
            })
            api_data.store = stores;
        }

        if (productFile) {
            api_data.image = productFile
        }

        if (id) {
            api_data.edit = true;
            api_data.id = id;
        }

        console.log(productFile)

        let result = await add_editProduct(account.token, api_data);
        if (result) {
            toast(result.message);
            if (account.role === "Admin") {
                navigate(UrlTypes.ADMIN_PRODUCT_LIST);
            }else {
                //window.history.back();
            }
        }
        setModalLoading(false);
    }

    const addProductStaff = async (published:boolean) => {
        if (!account) return;
        if (formData.name.length === 0) {
            toast("Product name is required");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            name: formData.name,
            size: formData.size.length > 0 ? formData.size : null,
            unit: formData.unit ? formData.unit.id : null,
            vendor: formData.vendor ? formData.vendor.id : null,
            description: formData.description.length > 0 ? formData.description : null,
            published: published
        };

        if (formData.store) {
            let stores:Array<number> = [];
            formData.store.forEach((element:generalObj) => {
                stores.push(element.id);
            })
            api_data.store = stores;
        }

        if (formData.product_image) {
            api_data.image = formData.product_image
        }

        let result = await add_editProduct(account.token, api_data);
        if (result) {
            toast(result.message);
            window.history.back();
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Product Add" desc="Create new products" >
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
            </PageBodyHeader>

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div style={{display:'flex', gap:"24px", alignItems:"center"}}>
                        <InputField label={"Product name"} inputProp={{value:formData.name}} onTextChange={(evt) => updateFormData("name", evt)} containerClass={"mb-w-100"} containerStyle={{width:"40%"}}  />
                    </div>

                    <div style={{display:'flex', flexWrap:"wrap", marginTop:"44px", gap:"24px", alignItems:"center"}}>
                        <InputDropdown label={"Vendor"} data={vendorList} placeHolder="Choose Vendor" selectedData={formData.vendor} containerClass={"mb-w-100"}  containerStyle={{width:"20%"}} onItemChange={(val) => updateFormData("vendor", val)} />
                        <InputDropdown label={"Store"} data={storeList} placeHolder="Select store(s)" selectedMulData={formData.store} containerClass={"mb-w-100 mb-mt-24"} mulitple={true}  containerStyle={{width:"20%"}} onItemChange={(val) => updateStoreList(val)} />
                        <div style={{display:'flex', alignItems:'center', gap:"8px"}}>
                            <div>
                                <CustomCheckBox checked={inputChecked} onChange={() => checkAll()} />
                            </div>
                            <div className="checkfield" style={{cursor:"pointer"}} onClick={() => checkAll()}>Check All</div>
                        </div>
                    </div>

                    <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"44px"}}>
                        <InputField label={"Size"} inputProp={{value:formData.size}} onTextChange={(evt) => updateFormData("size", evt)} containerClass={"mb-w-100"} containerStyle={{width:"20%"}} />
                        <InputDropdown label={"Unit"} data={unitList} placeHolder="Choose Unit" selectedData={formData.unit} containerClass={"mb-w-100"}  containerStyle={{width:"20%"}} onItemChange={(val) => updateUnitList(val)} />
                    </div>

                    <div style={{marginTop:"44px"}}>
                        <InputField label={"Description"} inputProp={{value:formData.description}} multiline={true} onTextChange={(evt) => updateFormData("description", evt)} customInputText={{ width:"100%", height:"100%" }} containerStyle={{width:"100%"}} inputContainerStyle={{height:"100px"}}  />
                    </div>

                    <div style={{marginTop:"44px"}}>
                        <UploadField label={"Product Image"} current_obj={formData.product_image} current_obj_url={formData.image ? formData.image : ""} upload_description="Drag and Drop a file to Upload (Max. 300kb)" file_types={"image/png, image/jpg, image/jpeg"} containerStyle={{width:"100%"}} inputContainerStyle={{height:"150px"}} onSelectFile={(val, isDrag) => pickImage(val, isDrag)} onInputClear={() => {updateFormData("product_image", null); updateFormData("image", "")}} />
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        {account && account.role === "Admin" ? (
                            <>
                                <ButtonFrame btn_text="Save & Publish" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addProduct(true)} />
                                <ButtonFrame btn_text="Save as Draft" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addProduct(false)} /> 
                            </>
                        ): (
                            <ButtonFrame btn_text="Save & Publish" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addProductStaff(true)} />
                        )}
                    </div>

                </>
            </Card>
            
            <AddUnitModal visible={openUnitAdd} modalClose={() => setOpenUnitAdd(false)} onAdded={(val) => {setUnitList([val, ...unitList]); setOpenUnitAdd(false)}} />
        </>
    )
}

export default AddProduct;