import { useCallback, useEffect, useRef, useState} from "react"
import { CloudSvg } from "../style/iconStyle"
import { generalObj } from "../constant/object_types";
import { getBlobUri } from "../action/generalFunc";
import { IoCloseCircle } from "react-icons/io5";
import { useDropzone } from "react-dropzone";

const UploadField = ({ label, upload_description, current_obj, current_obj_url, file_types, containerStyle, inputContainerStyle, onSelectFile, onInputClear }:{
    label ?: string,
    containerStyle ?: object,
    inputContainerStyle ?:  object,
    upload_description ?: string,
    file_types : string,
    current_obj ?: Blob | null,
    current_obj_url ?: string,
    onSelectFile : (val:generalObj, isDrage?:boolean) => void,
    onInputClear : () => void
}) => {

    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isImage, setIsImage] = useState<boolean>(false);

    const selectItem = useCallback((evt:generalObj, isDrag:boolean=false) => {
        onSelectFile(evt, isDrag);
    }, [onSelectFile]);

    const onDrop = useCallback((evt:generalObj) => {selectItem(evt[0], true)}, [selectItem]);

    const { getRootProps, getInputProps } = useDropzone({onDrop});

    useEffect(() => {
        setIsImage(current_obj || current_obj_url?.length ? true : false);
    }, [current_obj, current_obj_url]);

    const openFileSelector = () => {
        if (!inputRef.current) return;
        inputRef.current.value = "";
        inputRef.current.click();
    }

    const clearInput = () => {
        setIsImage(false);
        onInputClear();
    }

    return (
        <div className="inputFieldMain" style={containerStyle}>
            {label && (
                <div className="labelText">
                    <label>{label}</label>
                </div>
            )}
            {(current_obj && isImage) || (current_obj_url && isImage) ? (
                <div className="inputField__container file_upload" style={inputContainerStyle} onClick={openFileSelector}>
                    <div>
                        <div style={{width:"100px", height:"100px", position:"relative"}}>
                            <img style={{width:"100%", height:"100px", borderRadius:"10px"}} src={current_obj && current_obj.type !== "application/pdf" ? getBlobUri(current_obj) : current_obj_url} alt="product" />
                            <button style={{position:'absolute', width:"20px", height:"20px"}} onClick={clearInput}>
                                <span style={{display:'inline-flex', width:'100%', height:"100%"}}><IoCloseCircle size={"24px"} /></span>
                            </button>
                        </div>
                    </div>
                </div>
            ):(
                <div  {...getRootProps()} className="inputField__container file_upload" style={inputContainerStyle} onClick={openFileSelector}>
                    <input {...getInputProps()} type="file" ref={inputRef} accept={file_types} onChange={(evt) => selectItem(evt)} />
                    <div>
                        <span><CloudSvg /></span>
                        <span>{upload_description}</span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default UploadField;