import { IoAdd } from "react-icons/io5";
import { CardSearch, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchMessagesSent, timeStr } from "../../action/generalFunc";

const MessageObjList = () => {
    const navigate = useNavigate();
    const [messageList, setMessageList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchMessagesSent(account.token);
            if (result) {
                setMessageList(result.messages);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    return (
        <>
            <PageBodyHeader title="Message List" desc="Manage your messages">
                <button className="product_create_button" onClick={() => navigate(UrlTypes.STAFF_MESSAGE_ADD)}>
                    <span><IoAdd /></span>
                    <span>Compose Message</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["ID", "Subject", "Message", "User", "Last Update", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => setSearchText(evt)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {messageList.map((item, index) => (
                        <tr key={index}>
                            <td>#{item.id}</td>
                            <td>{item.subject}</td>
                            <td>
                                {item.messages.length > 0 ? (
                                    <div dangerouslySetInnerHTML={{__html: `${item.messages[item.messages.length - 1].message.slice(0, 38)}...`}} />
                                ): "None"}
                            </td>
                            <td>Admin</td>
                            <td>{`${dateStr(item.messages[item.messages.length - 1].created_at)} (${timeStr(item.messages[item.messages.length - 1].created_at)})`}</td>
                            <td>
                                <div className="edit__delete_action">
                                   
                                    <button onClick={() => navigate(`${UrlTypes.STAFF_MESSAGE_EDIT}${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px", height:"auto"}}>
                                        Reply
                                    </button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            </Table>
            
        </>
    )
}

export default MessageObjList;