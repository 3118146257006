
import { Chart as ChartJS, Tooltip, BarElement, LinearScale, CategoryScale } from 'chart.js';
import { Bar } from "react-chartjs-2";
import { generalObj } from '../constant/object_types';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip
);

const BarChart = ({ bar_data, start_color, end_color }:{
  bar_data:generalObj,
  start_color: string,
  end_color: string
}) => {
    const options:generalObj = {
        responsive: true,
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
        scales: {
          x: {
            border: {
                display: false
            },
            stacked: true,
            grid: {
                display: false
            }
          },
          y: {
            border: {
                display: false
            },
            stacked: true,
            grid: {
                display: false,
            },
            ticks: {
                display: false
            }
          },
        }
    }

    const labels = bar_data.label;//['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const data = {
        labels,
        datasets: [
          {
            label: 'Closing Stock',
            data: bar_data.closing_stock,
            backgroundColor: end_color,
            stack: 'Stack 0',
            barThickness: 12,
            borderRadius: 15
          },
          {
            label: 'Opening Stock',
            data: bar_data.opening_stock,
            backgroundColor: start_color,
            stack: 'Stack 0',
            barThickness: 12,
            borderRadius: 15
          },
        ],
    };

    return (
        <>
            <Bar options={options} data={data} />
        </>
    )
}

export default BarChart;