import { ButtonFrame, CardSearch, ExportData, PageBodyHeader, Table, UpdateExpiry } from "../../component";
import { useEffect, useState } from "react";
import { generalObj } from "../../constant/object_types";
import { useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchExpiryProduct, fetchProductExpListReport, fetchUserListById } from "../../action/generalFunc";
import { toast } from "react-toastify";
import { EditButtonSvg } from "../../style/iconStyle";

const StaffProductExpirySublevel = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productNextList, setProductNextList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [searchNextText, setSearchNextText] = useState<string>("");
    const [expiryEdit, setExpiryEdit] = useState<boolean>(false);
    const [expiryEditObj, setExpiryEditObj] = useState<generalObj | null>(null);
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");
    const [isExpiring, setIsExpiring] = useState<boolean>(false);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { id, store_id, child_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id || !child_id) return;
        (async () => {
            setModalLoading(true);
            const queryParameters = new URLSearchParams(window.location.search)
            let result = await fetchUserListById(account.token, id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let expiry_result = await fetchExpiryProduct(account.token, store_id, id, child_id)
                if (!expiry_result) {
                    window.history.back();
                }else {
                    setIsExpiring(queryParameters.get('expiring') ?  true : false)
                    setProductList(expiry_result.products);
                    setProductNextList(expiry_result.products_next);
                    setStoreName(`${expiry_result.store_data.name} - ${expiry_result.store_data.organization}`)
                    setVendorName(`${result.data.user.username}`)
                }
                setModalLoading(false);
            })();
        })();
    }, [account, id, store_id, child_id, setModalLoading]);

    const processExport = (export_type:string, expiry:string) => {
        generateReport(export_type, expiry);
    }

    const onSearch = (val:string, expiring:boolean=true) => {
        if (expiring) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || item.expiry_id.toString().toLowerCase().includes(val.toLowerCase())  || val.toLowerCase().includes(item.quantity) || dateStr(item.expiry_date).toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductNextList((prevProductNextList) => {
                return (
                    prevProductNextList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || item.expiry_id.toString().toLowerCase().includes(val.toLowerCase())  || val.toLowerCase().includes(item.quantity) || dateStr(item.expiry_date).toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchNextText(val);
        }
        
    }

    const generateReport = async (exportType:string, expiry:string) => {
        if (!exportType || !child_id || !account || !account.isAuthenticated || !id || !store_id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            expire_type:expiry,
            store: store_id,
            vendor: id,
            parent: child_id
        };
        
        let result = await fetchProductExpListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_expiry_staff.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    const closeModal = () => {
        setExpiryEdit(false); 
        setExpiryEditObj(null)
    }


    const updatedModal = () => {
        window.location.reload();
    }

    return (
        <>
            <PageBodyHeader title="About to Expire Product" desc="Checklist of about to expire product">
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
            </PageBodyHeader>

            {isExpiring ? (
                <div>
                    <Table table_head={["S/no", "Product name", "Size", "Quantity", "Expiry Date", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                    tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
                    tableAction={
                        <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                            <ExportData export_type={(val) => processExport(val, "under")} />
                        </div>
                    }
                    headerStyle={{marginBottom: "44px"}}
                    table_title={`About to expire products for ${vendorName.toUpperCase()} in ${storeName}`}
                    >
                        <>
                            {productList.map((item, index) => (
                                (item.show || item.show === undefined) && (
                                    <tr key={index}>
                                        <td>#{index+1}</td>
                                        <td>
                                            <div className="contentWithImg">
                                                <span><img src={item.image} alt={item.name} /></span>
                                                <span>{item.name}</span>
                                            </div>
                                        </td>
                                        <td>{item.size}</td>
                                        <td>{item.quantity}</td>
                                        <td>{dateStr(item.expiry_date)}</td>
                                        <td>
                                            <div className="edit__delete_action">
                                                <button onClick={() => {setExpiryEditObj(item); setExpiryEdit(true)}}>
                                                    <span>
                                                        <EditButtonSvg />
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ))}
                        </>
                    </Table>
                </div>
            ):(
                <div style={{marginTop:"32px"}}>
                    <Table table_head={["ID", "Product name", "Size", "Quantity", "Expiry Date", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                    tableSearch={<CardSearch value={searchNextText} onTextChange={(evt) => onSearch(evt, false)} />}
                    tableAction={
                        <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                            <ExportData export_type={(val) => processExport(val, "over")} />
                        </div>
                    }
                    headerStyle={{marginBottom: "44px"}}
                    table_title={`Products and their expiry for ${vendorName.toUpperCase()} in ${storeName}`}
                    >
                        <>
                            {productNextList.map((item, index) => (
                                (item.show || item.show === undefined) && (
                                    <tr key={index}>
                                        <td>#{item.expiry_id}</td>
                                        <td>
                                            <div className="contentWithImg">
                                                <span><img src={item.image} alt={item.name} /></span>
                                                <span>{item.name}</span>
                                            </div>
                                        </td>
                                        <td>{item.size}</td>
                                        <td>{item.quantity}</td>
                                        <td>{dateStr(item.expiry_date)}</td>
                                        <td>
                                            <div className="edit__delete_action">
                                                <button onClick={() => {setExpiryEditObj(item); setExpiryEdit(true)}}>
                                                    <span>
                                                        <EditButtonSvg />
                                                    </span>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            ))}
                        </>
                    </Table>
                </div>
            )}
            {expiryEditObj && <UpdateExpiry visible={expiryEdit} cur_expiry={expiryEditObj}  modalClose={()=> closeModal()} onAdded={() => updatedModal()} />}
        </>
    )
}

export default StaffProductExpirySublevel;