import { useState } from "react"
import { generalObj } from "../constant/object_types"
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

const InputDropdown = ({containerStyle, label, selectedData, selectedMulData, data, placeHolder, mulitple, containerClass, onItemChange}:{
    containerStyle?: object,
    label ?: string,
    selectedData ?: generalObj | null,
    selectedMulData ?: generalObj[] | null,
    placeHolder : string,
    data : generalObj[],
    mulitple ?: boolean,
    containerClass ?: string,
    onItemChange : (val:generalObj) => void
}) => {

    const [dropOpen, setDropOpen] = useState<boolean>(false);

    const checkActive = (id:number) => {
        if (!selectedMulData) return false;
        const result = selectedMulData.filter((item) => item.id === id);

        if (result.length > 0) {
            return true;
        }else {
            return false;
        }
    }
        
    return (
        <div className={`inputFieldMain inputDropDownMain ${containerClass}`} style={containerStyle}>
            {label && (
                <div className="labelText">
                    <label>{label}</label>
                </div>
            )}
            <div className="inputField__container dropField" onClick={() => setDropOpen(!dropOpen)}>
                <div className="dropValue">
                    {selectedData || selectedMulData ? (
                        mulitple ? (
                            selectedMulData && selectedMulData.length > 0 ? (selectedMulData.map((item:generalObj, index:number) => (
                                <span key={index}>{index === 0 ? item.label : `, ${item.label}`}</span>
                            ))):placeHolder
                        ): selectedData && selectedData.label
                    ):placeHolder}
                </div>
                <div >
                    <span>
                        {!dropOpen ? (
                            <IoChevronDown size={"18px"} color="#B2B5B2" />
                        ):(
                            <IoChevronUp size={"18px"} color="#B2B5B2" />
                        )}
                    </span>
                </div>
            </div>
            <div className={`inputDropDown ${dropOpen ? "active" : ""}`}>
                {mulitple ? (
                    <ul>
                        {data.map((item:generalObj, index:number) => (
                            <li key={index} className={checkActive(item.id) ? "active" : ""}>
                                <button onClick={() => {onItemChange(item)}}>{item.label}</button>
                            </li>
                        ))}
                    </ul>
                ): (
                    <ul>
                        {data.map((item:generalObj, index:number) => (
                            <li key={index} className={(selectedData && ((selectedData.id === item.id) || (selectedData.label === item.label))) ? "active" : ""}>
                                <button onClick={() => {setDropOpen(false); onItemChange(item)}}>{item.label}</button>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    )
}

export default InputDropdown;