import { IoAdd } from "react-icons/io5";
import { CardSearch, ConfirmationModal, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { DeleteButtonSvg, EditButtonSvg } from "../../style/iconStyle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, deleteUser, fetchUserList, fetchUserListReport, restrictUser } from "../../action/generalFunc";
import { FiAlertCircle, FiCheckCircle } from "react-icons/fi";
import { toast } from "react-toastify";

const StaffList = () => {
    const navigate = useNavigate();
    const [staffList, setStaffList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [openRestrictConfirmation, setOpenRestrictConfirmation] = useState<boolean>(false);
    const [itemToRestrict, setItemToRestrict] = useState<generalObj | null>(null);
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<generalObj | null>(null);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchUserList(account.token, "staffuser");
            if (result) {
                setStaffList(result.data.users);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const onRestrict = async () => {
        if (!account || !itemToRestrict) return;
        setModalLoading(true);
        let result = await restrictUser(account.token, itemToRestrict.id);
        let status = !itemToRestrict.status;
        if (result) {
            toast(result.message);
            setStaffList((prevStaffList) => {
                return (
                    prevStaffList.filter((item) => {
                        if (item.id === itemToRestrict.id) {
                            item.status = status;
                        }
                        return item;
                    })
                )
            });
        }
        setItemToRestrict(null);
        setOpenRestrictConfirmation(false)
        setModalLoading(false)
    }

    const onDelete = async () => {
        if (!account || !itemToDelete) return;
        setModalLoading(true);
        let result = await deleteUser(account.token, itemToDelete.id);
        if (result) {
            toast(result.message);
            setStaffList((prevStaffList) => {
                return (
                    prevStaffList.filter((item) => item.id !== itemToDelete.id)
                )
            });
        }
        setItemToDelete(null);
        setOpenDeleteConfirmation(false)
        setModalLoading(false)
    }

    const onSearch = (val:string) => {
        setStaffList((prevStaffList) => {
            return (
                prevStaffList.filter((item) => {
                    if (item.username.includes(val) || item.mobile.includes(val) || item.email.includes(val) || (item.store && val.includes(`${item.store.length}`))) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        generateReport(export_type)
    }

    const generateReport = async (export_type:string) => {
        if (!export_type || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let param = {
            type: export_type,
            user_type: "staff",
        };
        
        let result = await fetchUserListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `staff_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Staff List" desc="Manage staff">
                <button className="product_create_button" onClick={() => navigate(UrlTypes.ADMIN_STAFF_ADD)}>
                    <span><IoAdd /></span>
                    <span>Add Staff</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["S/no", "Username", "Phone", "Email", "Stores", "Created on", "Status", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {staffList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>#{index+1}</td>
                                <td>
                                    {item.username}
                                </td>
                                <td>{item.mobile}</td>
                                <td>{item.email}</td>
                                <td>{item.store ? item.store.length : 0}</td>
                                <td>{dateStr(item.created)}</td>
                                <td><div className={`status__container ${item.status ? "completed" : "pending"}`} style={{width:"80px", padding:"5px"}}>{item.status ? "Active" : "Restricted"}</div></td>
                                <td>
                                    <div className="edit__delete_action">
                                        <button title={item.status ? "Restrict" : "Active"} onClick={() => {setItemToRestrict(item); setOpenRestrictConfirmation(true)}}>
                                            <span>
                                                {item.status ? <FiAlertCircle size={24} color="#EF5F0F" /> : <FiCheckCircle size={24} color="#EF5F0F" />}
                                            </span>
                                        </button>
                                        <button onClick={() => navigate(`${UrlTypes.ADMIN_STAFF_EDIT}${item.id}`)}>
                                            <span>
                                                <EditButtonSvg />
                                            </span>
                                        </button>

                                        <button onClick={() => {setItemToDelete(item); setOpenDeleteConfirmation(true)}}>
                                            <span>
                                                <DeleteButtonSvg />
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            {itemToDelete && <ConfirmationModal visible={openDeleteConfirmation} title={"Delete Confirmation"} description={`Do you want to delete ${itemToDelete.username}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToDelete(null); setOpenDeleteConfirmation(false)}} onSubmit={() => onDelete()} />}
            {itemToRestrict && <ConfirmationModal visible={openRestrictConfirmation} title={`${itemToRestrict.status ? "Block" : "Unblock"} User Confirmation`} description={`Do you want to ${itemToRestrict.status ? "block" : "unblock"} user - ${itemToRestrict.username}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToRestrict(null); setOpenRestrictConfirmation(false)}} onSubmit={() => onRestrict()} />}
        </>
    )
}

export default StaffList;