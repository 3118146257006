const PageBodyHeader = ({ title, desc, inlineChild, children, style }:{
    title: string,
    desc ?: string,
    inlineChild ?: JSX.Element,
    children?: JSX.Element,
    style ?: object
}) => (
    <div className="pageHeader" style={{marginBottom: '37px', ...style}}>
        <div style={inlineChild && {display:"flex", gap:"12px", alignItems:"center"}}>
            {inlineChild && <div>{inlineChild}</div>}
            <div>
                <h3>{title}</h3>
                <p>{desc}</p>
            </div>
        </div>

        <div>
            {children}
        </div>
    </div>
)

export default PageBodyHeader;