import { generalObj } from "../../constant/object_types";
import { Action, ActionType } from "../actions/action_types";

const initialState:generalObj = {
    token: null,
    refresh_token: null,
    id: null,
    fullname: null,
    shortname: null,
    role: null,
    email: null,
    phone: null,
    expiry: null,
    isAuthenticated: false,
}

const TimpreReducer = (state=initialState, action:Action) => {
    switch(action.type) {
        case ActionType.LOGIN_USER :
            if (!action.payload) return;
            return ({
                ...state,
                ...action.payload,
                isAuthenticated: true
            })
        case ActionType.UPDATE_USER :
            if (!action.payload) return;
            return ({
                ...state,
                ...action.payload
            })
        case ActionType.LOGOUT_USER:
            return ({
                ...initialState
            })
        default:
            return state
    }
}

export default TimpreReducer;