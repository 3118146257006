import { CardSearch, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { IoAdd } from "react-icons/io5";
import { dateStr, fetchTicketRaised, timeStr } from "../../action/generalFunc";

const VendorTicketList = () => {
    const navigate = useNavigate();
    const [tickets, setTickets] = useState<generalObj[]>([])
    const [searchText, setSearchText] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchTicketRaised(account.token);
            if (result) {
                setTickets(result.tickets);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    return (
        <>
            <PageBodyHeader title="Tickets" desc="Tickets Raised - Solved and Pending">
                <button className="product_create_button" style={{borderColor:"#1DCB5F", color:"#00983A"}} onClick={() => navigate(UrlTypes.VENDOR_TICKET_RAISE)}>
                    <span><IoAdd /></span>
                    <span>Raise Ticket</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["ID", "Subject", "Message", "Store", "Staff", "Last Update", "Status", "Action"]} th_class="white" color={"#1DCB5F"} thColor={"#00983A"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => setSearchText(evt)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {tickets.map((item, index) => (
                        <tr key={index}>
                            <td>#{item.id}</td>
                            <td>{item.subject}</td>
                            <td>
                                {item.messages.length > 0 ? (
                                    <div dangerouslySetInnerHTML={{__html: `${item.messages[item.messages.length - 1].message.slice(0, 38)}...`}} />
                                ): "None"}
                            </td>
                            <td>{item.store.name} - {item.store.organization}</td>
                            <td>{item.staff ? item.staff.username : "Awaiting"}</td>
                            <td>{`${dateStr(item.messages[item.messages.length - 1].created_at)} (${timeStr(item.messages[item.messages.length - 1].created_at)})`}</td>
                            <td>
                                <div className={`status__container ${item.resolved ? "completed" : "pending"}`}>{item.resolved ? "Completed" : "Pending"}</div>
                            </td>
                            <td>
                                <div className="edit__delete_action">
                                   
                                    <button onClick={() => navigate(`${UrlTypes.VENDOR_TICKET_REPLY}${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px", height:"auto"}}>
                                        {item.resolved ? "View" : "Reply"}
                                    </button>

                                    {/* <button onClick={() => selectItemForDelete(item.id)}>
                                        <span>
                                            <DeleteButtonSvg />
                                        </span>
                                    </button> */}
                                </div>
                            </td>
                        </tr>
                    ))}
                </>
            </Table>
        </>
    )
}

export default VendorTicketList;