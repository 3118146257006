import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { AdminDashboard, StaffDashboard, VendorDashboard } from "../component";

const Dashboard = () => {

    const account = useSelector((state:RootState) => state.account);

    return (
        <>
            {account && account.isAuthenticated && (
                account.role === "Admin" ? (
                    <AdminDashboard />
                ): account.role === "Staff" ? (
                    <StaffDashboard />
                ): (
                    <VendorDashboard />
                )
            )}
        </>
    )
}

export default Dashboard;