import { useEffect, useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext } from "react-router-dom";
import { updateExpiry } from "../../action/generalFunc";
import { toast } from "react-toastify";

const UpdateExpiryModal = ({ visible, cur_expiry, modalClose, onAdded }:{
    visible: boolean,
    cur_expiry: generalObj,
    modalClose: () => void,
    onAdded: () => void,
}) => {

    const [formData, setFormData] = useState<generalObj>({
        expiry_qty: "",
        expiry_date: ""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    useEffect(() => {
        if (!account || !account.isAuthenticated || !cur_expiry) return;
        setFormData({
            expiry_qty: cur_expiry.quantity,
            expiry_date: cur_expiry.expiry_date
        });
    }, [account, cur_expiry]);

    const submitExpiry = async () => {
        if (!account || !account.isAuthenticated || !cur_expiry) return;
        if (formData.expiry_qty.length === 0) {
            toast("Expiry quantity is required");
            return;
        }
        if (parseInt(formData.expiry_qty) < 0) {
            toast("Expiry quantity is not a number");
            return;
        }
        if (formData.expiry_date === "") {
            toast("Expiry quantity date is required");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            expiry_id: cur_expiry.expiry_id,
            expiry_qty: formData.expiry_qty,
            expiry_date: formData.expiry_date
        };

        let result = await updateExpiry(account.token, api_data);
        if (result) {
            toast(result);
            setTimeout(() => {
                onAdded();
            }, 1500);
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Add Expiry</h4>
                    <div>
                        <InputField label={"Quantity"} inputProp={{value:formData.expiry_qty, placeholder:"Enter expiry quantity", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("expiry_qty", evt)} containerStyle={{width:"100%"}} />
                        <InputField label={`Expiry Date`} inputProp={{value:formData.expiry_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("expiry_date", evt)} containerStyle={{width:"100%", marginTop:"24px"}} />
                                        
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => submitExpiry()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}
export default UpdateExpiryModal;