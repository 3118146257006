import { ButtonFrame, CardSearch, ExportData, PageBodyHeader, ReportModal, Table, TableHeadElement } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, dateStr, fetchProductOrderSubListReport, fetchProductParentSubOrder } from "../../action/generalFunc";
import { toast } from "react-toastify";

const VendorProductSubList = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [openReportModal, setOpenReportModal] = useState<boolean>(false);
    const [exportType, setExportType] = useState<string | null>(null);
    const [rangeParam, setRangeParam] = useState<string | null>("");
    const [storeName, setStoreName] = useState<string>("");
    const [productName, setProductName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { store_id, order_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !store_id || !order_id) return;
        (async () => {
            setModalLoading(true)
            const queryParameters = new URLSearchParams(window.location.search)
            let result = await checkStoreByStoreIdUserId(account.token, store_id, account.id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
            }
            (async () => {
                let order_result;
                if (queryParameters.size > 0 && queryParameters.get('range')) {
                    let range = queryParameters.get('range');
                    order_result = await fetchProductParentSubOrder(account.token, store_id, order_id, account.id, range)
                    setRangeParam(range);
                }else {
                    order_result = await fetchProductParentSubOrder(account.token, store_id, order_id, account.id)
                }
                if (!order_result) {
                    window.history.back();
                    return;
                }
                setProductList(order_result.data);
                setProductName(order_result.parent);
                setModalLoading(false)
            })();
            
        })();
    }, [account, setModalLoading, store_id, order_id]);

    const onSearch = (val:string) => {
        let new_val = val.toLowerCase()
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (dateStr(item.date_from).toLowerCase().includes(new_val) || dateStr(item.date_to).toLowerCase().includes(new_val) || new_val.includes(item.size) || new_val.includes(item.quantity) || new_val.includes(item.open_stock) || new_val.includes(item.close_stock) || new_val.includes(item.ros) || new_val.includes(item.quantity_sold)) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        setExportType(export_type);
        setOpenReportModal(true);
    }

    const generateReport = async (val:generalObj) => {
        if (!exportType || !store_id || !order_id || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let date_from_obj = new Date(val.date_from);
        let date_to_obj = new Date(val.date_to);
        let param:generalObj = {
            type: exportType,
            date_from: date_from_obj.toISOString(),
            date_to: date_to_obj.toISOString(),
            store: store_id,
            parent: order_id,
        };
        if (rangeParam) {
            param.range = rangeParam;
        }
        
        let result = await fetchProductOrderSubListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_order_sub_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            setExportType(null);
            setOpenReportModal(false);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Product List" desc="Order details" >
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.VENDOR_PRODUCT_LIST}${store_id}`)} />
            </PageBodyHeader>

            <Table table_head={["Date From", "Date To", "Size", "SOH", "Open Stock", "Closing Stock", {
                element: <TableHeadElement title="Qty Sold" current_value={rangeParam} />
            }, {
                element: <TableHeadElement title="ROS" current_value={rangeParam} />
            }]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            containerCardStyle={{minHeight:"200px"}}
            table_title={`${productName} report for ${storeName}`}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            rangeParam && rangeParam==='monthly' ? (
                                <tr key={index}>
                                    <td>
                                        {item.date_from}
                                    </td>
                                    <td>
                                        {item.date_to}
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.open_stock}</td>
                                    <td>{item.close_stock}</td>
                                    <td>{item.quantity_sold}</td>
                                    <td>{item.ros}</td>
                                </tr>
                            ):(
                                <tr key={index}>
                                    <td>
                                        {dateStr(item.date_from)}
                                    </td>
                                    <td>
                                        {item.filled ? dateStr(item.date_to) : "-"}
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.open_stock}</td>
                                    <td>{item.filled ? item.close_stock : "-"}</td>
                                    <td>{item.filled ? item.quantity_sold : "-"}</td>
                                    <td>{item.filled ? item.ros : "-"}</td>
                                </tr>
                            )
                        )
                    ))}
                </>
            </Table>
            
            <ReportModal visible={openReportModal} modalClose={() => setOpenReportModal(false)} onAdded={(val) => generateReport(val)}  />
        </>
    )
}

export default VendorProductSubList;