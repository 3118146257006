import { CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchStoreListByUserId, fetchStoreListReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const StaffStoreList = () => {
    const navigate = useNavigate();
    const [storeList, setStoreList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchStoreListByUserId(account.token, account.id, "staff");
            if (result) {
                setStoreList(result.data.stores);
            }else {
                window.history.back();
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const onSearch = (val:string) => {
        setStoreList((prevStoreList) => {
            return (
                prevStoreList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || item.organization.toLowerCase().includes(val.toLowerCase())) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        generateReport(export_type)
    }

    const generateReport = async (exportType:String) => {
        if (!exportType || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let param = {
            type: exportType
        };
        
        let result = await fetchStoreListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `store_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Store List" desc="Check list of available Stores" />

            <Table table_head={["Organization", "Store name", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {storeList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>{item.organization}</td>
                                <td>{item.name}</td>
                                <td><button onClick={() => navigate(`${UrlTypes.STAFF_VENDOR_LIST}${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px"}}>View</button></td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
        </>
    )
}

export default StaffStoreList;