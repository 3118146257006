const CustomCheckBox = ({ title, checked, onChange }:{
    title ?: string
    checked: boolean,
    onChange: () => void,
}) => (
    <label className="custom__CheckBox">
        {title}
        <input type="checkbox" checked={checked} onChange={onChange} />
        <span className="checkmark"></span>
    </label>
)

export default CustomCheckBox;