import { CsvSvg, PdfSvg } from "../style/iconStyle";

const ExportData = ({ export_type }:{
    export_type?: (val:string) => void
}) => (
    <div className="export_data__container">
        <button onClick={() => export_type && export_type("pdf")}>
            <PdfSvg />
        </button>

        <button onClick={() => export_type && export_type("csv")}>
            <CsvSvg />
        </button>
    </div>
)

export default ExportData;