import { useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext, useParams } from "react-router-dom";
import { createOrderReceived } from "../../action/generalFunc";
import { toast } from "react-toastify";

const AddOrderReceivedModal = ({ visible, modalClose, onAdded }:{
    visible: boolean,
    modalClose: () => void,
    onAdded : () => void
}) => {

    const { order_id } = useParams();
    const [formData, setFormData] = useState<generalObj>({
        order_received: "",
        order_received_date: "",
        expiry_date: ""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    const addStore = async () => {
        if (!account || !account.isAuthenticated || !order_id) return;
        
        if (formData.order_received.length === 0) {
            toast("Order received is required");
            return;
        }
        if (!parseInt(formData.order_received)) {
            toast("Order received is not a number");
            return;
        }
        if (formData.order_received_date === "") {
            toast("Order received date is required");
            return;
        }
        if (formData.expiry_date === "") {
            toast("Expiry quantity date is required");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            parent_id: order_id,
            quantity_received: formData.order_received,
            date_received: formData.order_received_date,
            expiry_qty: formData.order_received,
            expiry_date: formData.expiry_date
        };

        let result = await createOrderReceived(account.token, api_data);
        if (result) {
            toast(result);
            setTimeout(() => {
                onAdded();
            }, 2000);
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Add Order Received</h4>
                    <div>

                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"44px"}}>
                            <InputField label={"Order Received *"} inputProp={{value:formData.order_received, placeholder:"Enter Qty", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("order_received", evt)} containerStyle={{width:"50%"}} />
                            <InputField label={"Date *"} inputProp={{value:formData.order_received_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("order_received_date", evt)} containerStyle={{width:"50%"}} />
                        </div>
                        <div style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"44px"}}>
                            <InputField label={`Expiry Date`} inputProp={{value:formData.expiry_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("expiry_date", evt)} containerStyle={{width:"100%"}} />
                        </div>
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addStore()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default AddOrderReceivedModal;