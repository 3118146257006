import { useNavigate, useOutletContext } from "react-router-dom";
import Table from "../Table";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { DamagesSvg, ExpiredProductSvg, ProductSvg, TicketSvg } from "../../style/iconStyle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchDashboard, timeStr } from "../../action/generalFunc";

const VendorDashboard = () => {
    const navigate = useNavigate();

    const [expiringProduct, setExpiringProduct] = useState<generalObj[]>([]);

    const [tickets, setTickets] = useState<generalObj[]>([]);

    const [dashboardStat, setDashboardStat] = useState<generalObj>({
        ticket: 0,
        product: 0,
        expiry: 0,
        damage: 0
    })

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchDashboard(account.token);
            if (result) {
                setDashboardStat({...result.data.stat});
                setTickets(result.data.tickets);
                setExpiringProduct(result.data.expiry);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    return (
        <>
            <div className="col__stats">
                <div className="col__statsItem" style={{backgroundColor:"#EF5F0F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.ticket}</span>
                        <span>Pending Tickets</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <TicketSvg />
                    </div>
                </div>

                <div className="col__statsItem" style={{backgroundColor:"#1DCB5F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.product}</span>
                        <span>Products</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <ProductSvg />
                    </div>
                </div>

                <div className="col__statsItem" style={{backgroundColor:"#EB212F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.expiry}</span>
                        <span>Expiry</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <ExpiredProductSvg />
                    </div>
                </div>

                <div className="col__statsItem">
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.damage}</span>
                        <span>Damaged</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <DamagesSvg />
                    </div>
                </div>
            </div>

            <div className="col__summary">
                <div style={{width:"100%"}}>
                    <Table title="About to Expire Products" table_head={["S/no", "Order code", "Product name", "Size", "Store", "Quantity", "Expiry Date "]} th_class="white" color={"#1159AB"} thColor={"#1159AB"}>
                        <>
                            {expiringProduct.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>#{item.expiry_id}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.store.name} - {item.store.organization}</td>
                                    <td>{item.quantity}</td>
                                    <td>{dateStr(item.expiry_date)}</td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>


            <div className="col__summary">
                <div style={{width:"100%"}}>
                    <Table title="Tickets" table_head={["ID", "Subject", "Message", "Store", "Staff", "Last Update", "Status", "Action"]} th_class="white" color={"#45BA72"} thColor={"#00983A"}>
                        <>
                            {tickets.map((item, index) => (
                                <tr key={index}>
                                    <td>#{item.id}</td>
                                    <td>{item.subject}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{__html: `${item.messages.message.slice(0, 38)}...`}} />
                                    </td>
                                    <td>{item.store.name} - {item.store.organization}</td>
                                    <td>{item.staff ? item.staff.username : "Awaiting"}</td>
                                    <td>{`${dateStr(item.messages.created_at)} (${timeStr(item.messages.created_at)})`}</td>
                                    <td>
                                        <div className={`status__container ${item.resolved ? "completed" : "pending"}`}>{item.resolved ? "Completed" : "Pending"}</div>
                                    </td>
                                    <td>
                                        <div className="edit__delete_action">
                                        
                                            <button onClick={() => navigate(`${UrlTypes.VENDOR_TICKET_REPLY}${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px", height:"auto"}}>
                                                {item.resolved ? "View" : "Reply"}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>
        </>
    )
}

export default VendorDashboard;