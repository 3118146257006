import { IoSearchOutline } from "react-icons/io5";
import InputField from "./InputField";

const CardSearch = ({ value, onTextChange }:{
    value:string,
    onTextChange: (evt:any) => void
}) => (
    <InputField 
        inputProp={{value:value, type:"text", placeholder:"Search"}} 
        onTextChange={(evt) => onTextChange(evt)}  
        isIconLeft={<IoSearchOutline size={"24px"} color="#999C99" />}
        leftIconStyle = {{width:"18px", height:"18px"}}
    />
)

export default CardSearch;