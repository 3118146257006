import Card from "./Card";
import { generalObj } from "../constant/object_types";

const Table = ({ title, tableSearch, tableAction, children, styleClass, th_class, table_head, color, thColor, table_title, headerStyle, containerCardStyle }:{
    title ?: string,
    children : JSX.Element,
    th_class?: string, 
    styleClass ?: string | '',
    table_head : Array<string | generalObj>,
    tableSearch ?: JSX.Element,
    tableAction ?: JSX.Element,
    table_title ?: string,
    color?: string,
    thColor ?: string,
    headerStyle ?: object,
    containerCardStyle ?: object
}) => {
    return (
        <Card color={color} title={title} styleClass={styleClass} headerStyle={headerStyle} containerCardStyle={containerCardStyle} cardSearch={tableSearch} cardAction={tableAction} >
            <>
                <div>
                    {table_title && (
                        <h3 style={{marginBottom:"18px", textAlign:"center"}}>{table_title}</h3>
                    )}
                    <table className="tableComponent">
                        <thead>
                            <tr>
                                {table_head.map((item, index) => (
                                    typeof(item) === "object" ? (
                                        <th className={`headerText ${th_class}`} key={index} style={{backgroundColor:thColor}}>
                                            {item.element}
                                        </th>
                                    ): (
                                        <th className={`headerText ${th_class}`} key={index} style={{backgroundColor:thColor}}>{item}</th>
                                    )
                                ))} 
                            </tr>
                        </thead>
                        <tbody>
                            {children}
                        </tbody>
                    </table>
                </div>
            </>
        </Card>
    )
}

export default Table;