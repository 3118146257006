import { useLocation, useNavigate } from "react-router-dom"
import Navigation from "./Navigation"
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import { UrlTypes } from "../constant/object_types";

const ParentContainer = ({ children }:{
    children:JSX.Element
}) => {
    const [expandMenu, setExpandMenu] = useState<boolean>(false);
    const location_obj = useLocation();
    const location_pathname = location_obj.pathname.split("/")[2];
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (account && account.isAuthenticated) return;
        navigate(UrlTypes.HOME);
    }, [account, navigate]);

    return (
        <>
            <Navigation location={location_pathname} role={account?.role} onExpand={(val) => setExpandMenu(val)} />
            <div className={`body__container ${expandMenu ? "expand" : ""}`}>
                {children}
            </div>
            <ToastContainer theme="dark" />
        </>
    )
}

export default ParentContainer;