const Card = ({ color, children, style, styleClass, title, cardAction, cardSearch, headerStyle, containerCardStyle }: {
    color?: string,
    title ?: string,
    style ?: object,
    styleClass ?: string | '',
    children: JSX.Element,
    cardAction ?: JSX.Element,
    cardSearch ?: JSX.Element,
    headerStyle ?: object,
    containerCardStyle ?: object
}) => {
    return (
        <div className={`boxCard ${styleClass}`} style={{borderColor:color, ...style}}>
            <div style={containerCardStyle}>
                {(title || cardSearch || cardAction) && (
                    <div className="card__header" style={headerStyle}>
                        {title && (
                            <div className="card__title" style={{color:color}}>{title}</div>
                        )}
                        {cardSearch && (
                            <div>
                                {cardSearch}
                            </div>
                        )}
                        <div>
                            {cardAction}
                        </div>
                    </div>
                )}
                
                {children}
            </div>
        </div>
    )
}

export default Card;