import { ActionDropDown, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchExpiryParentProduct, fetchProductExpListParentReport, fetchStoreList, fetchUserList } from "../../action/generalFunc";
import { toast } from "react-toastify";
import ReportAdminModal from "../../component/modal/ReportAdminModal";

const ProductExpiry = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productNextList, setProductNextList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [searchNextText, setSearchNextText] = useState<string>("");
    const navigate = useNavigate();

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const [vendorList, setVendorList] = useState<generalObj[]>([
        {
            id: 0,
            label: 'All',
            value: 'all'
        }
    ]);
    const [storeList, setStoreList] = useState<generalObj[]>([
        {
            id: 0,
            label: 'All',
            value: 'all'
        }
    ]);
    const [openExpiryModal, setOpenExpiryModal] = useState<boolean>(false);
    const [exportTypes, setExportTypes] = useState<generalObj>({
        export_type: '',
        expire_type: ''
    });
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let expiry_result = await fetchExpiryParentProduct(account.token)
            if (!expiry_result) {
                window.history.back();
            }
            setProductList(expiry_result.products);
            setProductNextList(expiry_result.products_next);
            let result = await fetchUserList(account.token, "vendor");
            if (result) {
                let user_result:generalObj[] = [
                    {
                        id: 0,
                        label: 'All',
                        value: ''
                    }
                ];
                result.data.users.forEach((element:generalObj) => {
                    user_result.push({
                        id: element.id,
                        label: element.username,
                        value: element.id
                    })
                });
                setVendorList(user_result);
            }
            let sResult = await fetchStoreList(account.token);
            if (sResult) {
                let store_result:generalObj[] = [
                    {
                        id: 0,
                        label: 'All',
                        value: ''
                    }
                ];
                sResult.data.store.forEach((element:generalObj) => {
                    store_result.push({
                        id: element.id,
                        label: `${element.name} - ${element.organization}`,
                        value: element.id
                    });
                });
                setStoreList(store_result);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const processExport = (export_type:string, expiry:string) => {
        setExportTypes({
            export_type: export_type,
            expire_type: expiry
        });
        setOpenExpiryModal(true);
    }

    const onSearch = (val:string, expiring:boolean=true) => {
        if (expiring) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductNextList((prevProductNextList) => {
                return (
                    prevProductNextList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchNextText(val);
        }
        
    }

    const generateReport = async (export_type:string, expiry:string, paramObj:generalObj) => {
        if (!export_type || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let param:generalObj = {
            type: export_type,
            expire_type:expiry
        };
        if (paramObj.vendor.value !== '') {
            param.vendor = paramObj.vendor.value;
        }

        if (paramObj.store.value !== '') {
            param.store = paramObj.store.value;
        }
        
        let result = await fetchProductExpListParentReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_expiry_admin.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="About to Expire Products" desc="View/Search about to expire products" />

            <div>
                <Table table_head={["S/no", "Product name", "Size", "Quantity", "Store",  "Vendor", "Email Status", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
                tableAction={<ExportData export_type={(val) => processExport(val, "under")} />}
                headerStyle={{marginBottom: "44px"}}
                table_title={`About to expire products`}
                >
                    <>
                        {productList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{index+1}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.product_store}</td>
                                    <td>{item.product_vendor}</td>
                                    <td><div className={`status__container ${item.not_sent.length === 0 ? "completed" : "pending"}`} style={{width:"80px", padding:"5px"}}>{item.not_sent.length === 0 ? "Sent" : "Not sent"}</div></td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.ADMIN_PRODUCT_EXPIRING_SUB}${item.id}/?expiring=true`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>

            <div style={{marginTop:"32px"}}>
                <Table table_head={["S/no", "Product name", "Size", "Quantity", "Store", "Vendor", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchNextText} onTextChange={(evt) => onSearch(evt, false)} />}
                tableAction={<ExportData export_type={(val) => processExport(val, "over")} />}
                headerStyle={{marginBottom: "44px"}}
                table_title={`Products and their expiry`}
                >
                    <>
                        {productNextList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{index+1}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.product_store}</td>
                                    <td>{item.product_vendor}</td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.ADMIN_PRODUCT_EXPIRING_SUB}${item.id}/`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>
            <ReportAdminModal visible={openExpiryModal} storeList={storeList} vendorList={vendorList} modalClose={() => setOpenExpiryModal(false)} onAdded={(val) => generateReport(exportTypes.export_type, exportTypes.expire_type, val)} />
        </>
    )
}

export default ProductExpiry;