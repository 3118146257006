import { useEffect, useState } from "react";
import { ButtonFrame, Card, PageBodyHeader, UploadField } from "../../component"
import { generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { add_editProduct, getBlobUri, getCSVObject, getOrCreateCatUnit } from "../../action/generalFunc";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import { URL_ORIGIN } from "../../constant/constant";

const ImportProduct = () => {

    const [formData, setFormData] = useState<generalObj>({
        image:""
    });

    const [productForUpload, setProductForUpload] = useState<generalObj[] | null>(null);
    const [isUploaded, setIsUploaded] = useState<boolean>(false);

    const [upload_count, setUploadCount] = useState<number>(0);
    const [error_count, setErrorCount] = useState<number>(0);

    const {setModalLoading, setModalMessage}:{setModalLoading:Function, setModalMessage:Function} = useOutletContext()

    const account = useSelector((state:RootState) => state.account);

    useEffect(() => {
        if (!account) return;
        setModalLoading(false);
    }, [account, setModalLoading]);

    useEffect(() => {
        setModalMessage(
            <>
                <span>Uploading Product, please wait...</span>
                <br />
                <strong>Errors: </strong>{error_count}
                <br />
                <strong>Uploaded: </strong>{upload_count}
            </>
        );
    }, [error_count, upload_count, setModalMessage]);

    useEffect(() => {
        if (!isUploaded || (error_count === 0 && upload_count === 0)) return;
        setModalMessage("");
        toast(`Upload status, Errors: ${error_count}. Uploaded: ${upload_count}`);
        setIsUploaded(false);
    }, [isUploaded, setModalMessage, error_count, upload_count]);

    const downloadSampleFile = () => {
        const url = `${URL_ORIGIN}media/product_file/product_sample.csv`
        const fileName = url.split("/").pop();
        if (!fileName) return;

        const aTag = document.createElement("a");
        aTag.href = url;
        aTag.setAttribute("download", fileName);
        document.body.appendChild(aTag);
        aTag.click();
        aTag.remove();
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const pickCsv = async (e:generalObj, isDrag:boolean=false) => {
        if ((!isDrag && !e.target.files.length)) return;
        const reader = new FileReader();
        const file = !isDrag ? e.target.files[0] : e;
        if (file.type !== "text/csv") {
            toast("Only CSV files are allowed");
            return;
        }
        if (Math.floor(file.size / 1000) > 300) {
            toast("Image size is greater than 300kb");
            return;
        }
        await reader.readAsArrayBuffer(file);
        reader.onload = async () => {
            if (!reader || !reader.result) return;

            const blob = new Blob([reader.result], {type:"image/png"});
            const url = await getBlobUri(blob);
            const result = await getCSVObject(url);
            if (result) {
                const formatted_text:Array<string> = result.split("\n");
                let upload_list:generalObj[] = []
                formatted_text.forEach((element, index) => {
                    if (index === 0) return;
                    let new_split = element.split(",");
                    upload_list.push({
                        name: new_split[0],
                        category: new_split[4].replaceAll("\r", ""),
                        size: new_split[2],
                        unit: new_split[3],
                        description: new_split[1]
                    });
                });
                setProductForUpload(upload_list);
                updateFormData("image", require("../../assets/img/csv_icon.png"));
            }
        }
    }

    const addProduct = async () => {
        if (!account || !productForUpload) return;
        setModalLoading(true);
        await (async () => {
            productForUpload.forEach(async (element:generalObj) => {
                if (element.name.length === 0) {
                    toast("One product missing name");
                    setErrorCount(error_count+1);
                    return;
                }
                if (element.size.length === 0) {
                    toast("One product is missing size");
                    setErrorCount(error_count+1);
                    return;
                }
                if (element.unit.length === 0) {
                    toast("One product is missing unit");
                    setErrorCount(error_count+1);
                    return;
                }
                if (element.category.length === 0) {
                    toast("One product is missing category");
                    setErrorCount(error_count+1);
                    return;
                }
    
                let cat_unit_obj = await getOrCreateCatUnit(account.token, {
                    category: element.category,
                    unit: element.unit
                })
                if (!cat_unit_obj) {
                    setErrorCount(error_count+1);
                    return;
                }
    
                let api_data:generalObj = {
                    name: element.name,
                    category: cat_unit_obj.data.category,
                    size: element.size,
                    unit: cat_unit_obj.data.unit,
                    description: element.description,
                };
        
                let result = await add_editProduct(account.token, api_data);
                if (result) {
                    setUploadCount(upload_count+1);
                }
            })
            return true;
        })();
        setIsUploaded(true);
        updateFormData("image", "");
        setProductForUpload(null);
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Import Product" desc="Bulk Upload your Product" />

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div style={{display:'flex', gap:"24px", alignItems:"center"}}>
                        <div className="inputFieldMain" style={{width:"20%"}}>
                            <div className="labelText">
                                <label style={{fontWeight:"400"}}>Field must be in CSV format</label>
                            </div>
                            <ButtonFrame btn_text="Download Sample File" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => downloadSampleFile()} />
                        </div>
                    </div>

                    <div style={{marginTop:"44px"}}>
                        <UploadField label={"Product CSV file"} current_obj={formData.product_image} current_obj_url={formData.image ? formData.image : ""} upload_description="Drag and Drop a file to Upload (Max. 300kb)" file_types={"text/csv"} containerStyle={{width:"100%"}} inputContainerStyle={{height:"150px"}} onSelectFile={(val, isDrag) => pickCsv(val, isDrag)} onInputClear={() => {updateFormData("image", "")}} />
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Upload" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => {
                            if (!productForUpload) {
                                toast("CSV file is required");
                                return;
                            }
                            addProduct();
                        }} />
                    </div>

                </>
            </Card>
        </>
    )
}

export default ImportProduct;