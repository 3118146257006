import { useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext } from "react-router-dom";
import { addUnit } from "../../action/generalFunc";
import { toast } from "react-toastify";

const AddUnitModal = ({ visible, modalClose, onAdded }:{
    visible: boolean,
    modalClose: () => void,
    onAdded : (val:generalObj) => void
}) => {
    const [formData, setFormData] = useState<generalObj>({
        label: "",
        description: ""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    const submitAddUnit = async () => {
        if (!account || formData.label.length === 0 || formData.description.length === 0) return;
        setModalLoading(true);
        let result = await addUnit(account.token, formData);
        if (result) {
            toast(result.message);
            onAdded(result.data);
        }
        setModalLoading(false);
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Add New Unit</h4>
                    <div>
                        <InputField label={"Title"} inputProp={{value:formData.label}} onTextChange={(evt) => setFormData({...formData, label:evt})} containerStyle={{width:"100%", marginBottom:"20px"}} />
                        <InputField label={"Description"} inputProp={{value:formData.description}} onTextChange={(evt) => setFormData({...formData, description:evt})} containerStyle={{width:"100%", marginBottom:"20px"}} />
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => submitAddUnit()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default AddUnitModal;