import { ActionDropDown, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, fetchDamageProduct, fetchProductDmgListReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const VendorProductDamage = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            setModalLoading(true);
            let result = await checkStoreByStoreIdUserId(account.token, id, account.id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let dmg_result = await fetchDamageProduct(account.token, id)
                if (!dmg_result) {
                    window.history.back();
                }
                setProductList(dmg_result.products);
                setStoreName(`${dmg_result.store_data.name} - ${dmg_result.store_data.organization}`)
                setModalLoading(false)
            })();
        })();
    }, [account, setModalLoading, id]);

    const processExport = (export_type:string) => {
        generateReport(export_type);
    }

    const onSearch = (val:string) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.damaged) || val.toLowerCase().includes(item.returns) || val.toLowerCase().includes(item.return_date)) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const generateReport = async (exportType:string) => {
        if (!exportType || !account || !account.isAuthenticated || !id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            store: id
        };
        
        let result = await fetchProductDmgListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_damage_parent_vendor.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Damages and Returns" desc="List of Damaged and Returned Products">
                <button className="product_create_button" style={{color:"#FFF", backgroundColor:"#676967", borderColor:"#676967"}} onClick={() => window.history.back()}>
                    <span>Back</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["ID", "Product name", "Size", "Quantity Damaged",  "Quantity Returned", "Date", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            table_title={`Damage and Return for ${storeName}`}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>#{index+1}</td>
                                <td>
                                    <div className="contentWithImg">
                                        <span><img src={item.image} alt={item.name} /></span>
                                        <span>{item.name}</span>
                                    </div>
                                </td>
                                <td>{item.size}</td>
                                <td>{item.damaged}</td>
                                <td>{item.returns}</td>
                                <td>{item.return_date.map((rItem:string, index:number) => (
                                    <div key={index}>{rItem}</div>
                                ))}</td>
                                <td>
                                    <div className="edit__delete_action">
                                        <ActionDropDown>
                                            <ul>
                                                <li>
                                                    <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_DAMAGED_CHILD}${id}/${item.order_id}`)}>View</button>
                                                </li>
                                            </ul>
                                        </ActionDropDown>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
        </>
    )
}

export default VendorProductDamage;