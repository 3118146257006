import { generalObj } from "../constant/object_types";

const InputField = ({ label, inputProp, customClassInputText, customInputText, inputContainerStyle, multiline, isIconLeft, isIconRight, leftIconStyle, containerStyle, containerClass, iconPress, onTextChange }:{
    label ?: string, 
    inputProp:generalObj,
    isIconLeft ?: JSX.Element,
    isIconRight ?: JSX.Element,
    multiline ?: boolean,
    customClassInputText ?: string,
    customInputText ?: object,
    leftIconStyle ?: object,
    containerStyle ?: object,
    inputContainerStyle ?: object,
    containerClass ?: string
    iconPress ?: () => void,
    onTextChange : (evt:any) => void
}) => (
    <div className={`inputFieldMain ${containerClass}`} style={containerStyle}>
        {label && (
            <div className="labelText">
                <label>{label}</label>
            </div>
        )}
        <div className="inputField__container" style={{maxHeight:!multiline ? "40px" : "", ...inputContainerStyle}}>
            {isIconLeft && (
                <span style={leftIconStyle}>{isIconLeft}</span>
            )}
            {!multiline ? (
                <input className={`${customClassInputText}`} {...inputProp} style={customInputText}  onChange={(event) => onTextChange(event.target.value)} />
            ): (
                <textarea className={`${customClassInputText}`} {...inputProp} style={customInputText} onChange={(event) => onTextChange(event.target.value)} />
            )}
            {isIconRight && (
                <button style={{display:"flex"}} onClick={iconPress}>
                    <span style={{display:"inline-flex"}}>{isIconRight}</span>
                </button>
            )}
        </div>
    </div>
)

export default InputField