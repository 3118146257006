import { Dispatch } from "react";
import { Action, ActionType } from "./action_types";
import { API_URL } from "../../constant/constant";
import axios from "axios";
import { toast } from "react-toastify";
import { generalObj } from "../../constant/object_types";

export const login = (email:string, password:string) => async (dispatch: Dispatch<Action>) => {
    var config = {
        method: 'post',
        url:`${API_URL}account/login/`,
        data: {
            email: email,
            password: password
        }
    };
    
    let result = await axios(config)
    .then((response) => {
        dispatch({
            type: ActionType.LOGIN_USER,
            payload: response.data.data
        });    
        return true;
    })
    .catch((error) => {
        if (error.response && error.response.data.message) {
            toast(error.response.data.message);
        }else {
            toast('Error connecting to server, try again later');
        }
         
        return false
    })

    return result;
}

export const updateUser = (token:string, data:generalObj) => async (dispatch: Dispatch<Action>) => {
  
    let config = {
      method: "PUT",
      url:`${API_URL}account/user/update/`,
      headers: { 
        'Authorization': `Bearer ${token}`, 
        'Content-Type': 'application/json'
      },
      data: data
    }
  
    let result = await axios(config)
    .then((resp) => {
        dispatch({
            type: ActionType.UPDATE_USER,
            payload: {
                fullname: `${data.firstname} ${data.lastname}`,
                phone: data.mobile,
                email: data.email
            }
        });    
        return resp.data
    })
    .catch((error) => {
      if (error.response && error.response.data.message) {
        toast(error.response.data.message);
      }else {
        toast("Unable to process your request, kindly try again later");
      }
      return false;
    });
  
    return result
}

export const logout = () => async (dispatch: Dispatch<Action>) => {
    dispatch({
        type: ActionType.LOGOUT_USER
    });    

    return true;
}