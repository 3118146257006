import { ButtonFrame, CardSearch, ExportData, PageBodyHeader, Table, UpdateDamagesModal } from "../../component";
import { useEffect, useState } from "react";
import { generalObj } from "../../constant/object_types";
import { useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchDamageProductChild, fetchProductDmgListChildReport, fetchProductDmgListReturnReport, fetchUserListById } from "../../action/generalFunc";
import { toast } from "react-toastify";
import { EditButtonSvg } from "../../style/iconStyle";

const StaffProductDamageSublevel = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productReturnList, setProductReturnList] = useState<generalObj[]>([]);
    const [productInfo, setProductInfo] = useState<string>("");
    const [searchText, setSearchText] = useState<string>("");
    const [searchTextRet, setSearchTextRet] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");
    const [damageEditObj, setDamageEditObj] = useState<generalObj|null>(null);
    const [damageEdit, setDamageEdit] = useState<boolean>(false);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { id, store_id, product_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id || !product_id) return;
        (async () => {
            setModalLoading(true);
            let result = await fetchUserListById(account.token, id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let dmg_result = await fetchDamageProductChild(account.token, store_id, id, product_id);
                if (!dmg_result) {
                    window.history.back();
                }else {
                    setProductList(dmg_result.products_damage);
                    setProductReturnList(dmg_result.products_return);
                    setProductInfo(`${dmg_result.product_info.name} ${dmg_result.product_info.size}`);
                    setStoreName(`${dmg_result.store_data.name} - ${dmg_result.store_data.organization}`);
                    setVendorName(`${result.data.user.username}`);
                    setModalLoading(false)
                }
                
            })();
        })();
    }, [account, id, store_id, product_id, setModalLoading]);


    const processExport = (export_type:string) => {
        generateReport(export_type)
    }


    const processExportsec = (export_type:string) => {
        generateReportReturn(export_type)
    }

    const onSearch = (val:string, damaged?:boolean) => {
        if (damaged) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.comment.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.damage) || val.toLowerCase().includes(item.returns) || val.toLowerCase().includes(item.return_date)) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductReturnList((prevProductReturnList) => {
                return (
                    prevProductReturnList.filter((item) => {
                        if (item.return_qty.toString().toLowerCase().includes(val) || item.return_date.toString().toLowerCase().includes(val)) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchTextRet(val);
        }
    }

    const generateReport = async (exportType:string) => {
        if (!exportType || !account || !account.isAuthenticated || !store_id || !id || !product_id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            store: store_id,
            vendor: id,
            product_id:product_id
        };
        
        let result = await fetchProductDmgListChildReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_damage_staff.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    const generateReportReturn = async (exportType:string) => {
        if (!exportType || !account || !account.isAuthenticated || !store_id || !id || !product_id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            store: store_id,
            vendor: id,
            product_id:product_id
        };
        
        let result = await fetchProductDmgListReturnReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_return_staff.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    const closeModal = () => {
        setDamageEdit(false); 
        setDamageEditObj(null)
    }
    const updatedModal = () => {
        window.location.reload();
    }

    return (
        <>
            <PageBodyHeader title="Damages and Returns" desc="List of Damaged and Returned Products" />

            <div>
                <Table table_head={["ID", "Quantity Damaged",  "Comments", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt, true)} />}
                tableAction={
                    <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                        <ExportData export_type={(val) => processExport(val)} />
                        <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>
                }
                headerStyle={{marginBottom: "44px"}}
                table_title={`${productInfo} Damages for ${vendorName.toUpperCase()} in ${storeName}`}
                >
                    <>
                        {productList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{item.id}</td>
                                    <td>{item.damage}</td>
                                    <td>{item.comment}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <button onClick={() => {setDamageEditObj(item); setDamageEdit(true)}}>
                                                <span>
                                                    <EditButtonSvg />
                                                </span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>

            <div style={{marginTop:"32px"}}>
                <Table table_head={["ID", "Return Quantity",  "Return Date"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchTextRet} onTextChange={(evt) => onSearch(evt, false)} />}
                tableAction={
                    <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                        <ExportData export_type={(val) => processExportsec(val)} />
                    </div>
                }
                headerStyle={{marginBottom: "44px"}}
                table_title={`${productInfo} Returns for ${vendorName.toUpperCase()} in ${storeName}`}
                >
                    <>
                        {productReturnList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{item.id}</td>
                                    <td>{item.return_qty}</td>
                                    <td>{item.return_date}</td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>
            {damageEditObj && <UpdateDamagesModal visible={damageEdit} cur_damage={damageEditObj}  modalClose={()=> closeModal()} onAdded={() => updatedModal()} />}
        </>
    )
}

export default StaffProductDamageSublevel;