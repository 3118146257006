import { useEffect, useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext } from "react-router-dom";
import { updateDamageProductChild } from "../../action/generalFunc";
import { toast } from "react-toastify";

const AddDamagesModal = ({ visible, cur_damage, modalClose, onAdded }:{
    visible: boolean,
    cur_damage: generalObj,
    modalClose: () => void,
    onAdded : () => void
}) => {

    const [formData, setFormData] = useState<generalObj>({
        damages:"",
        comment:""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 


    useEffect(() => {
        if (!account || !account.isAuthenticated || !cur_damage) return;
        setFormData({
            id: cur_damage.id,
            damages: cur_damage.damage,
            comment: cur_damage.comment
        });
    }, [account, cur_damage]);

    const updateDamage = async () => {
        if (!account || !account.isAuthenticated) return;
        if (formData.damages.length === 0) {
            toast("Damages is required");
            return;
        }
        if (parseInt(formData.damages) < 0) {
            toast("Damages is not a number");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            stock_id: formData.id,
            damage: formData.damages,
            comment: formData.comment
        };

        let result = await updateDamageProductChild(account.token, api_data);
        if (result) {
            toast(result.message);
            onAdded();
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Update Damages</h4>
                    <div>
                        <InputField label={"Damages"} inputProp={{value:formData.damages, placeholder:"Enter number damage products", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("damages", evt)} containerStyle={{width:"100%"}} />
                        <InputField label={"Damages Comment"} inputProp={{value:formData.comment}} multiline={true} onTextChange={(evt) => updateFormData("comment", evt)} customInputText={{ width:"100%", height:"100%" }} containerStyle={{width:"100%", marginTop:"24px"}} inputContainerStyle={{height:"50px"}}  />
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => updateDamage()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default AddDamagesModal;