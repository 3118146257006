type payloadData = {
    [key:string]: any
}

/* User Account action type */
export enum ActionType {
    LOGIN_USER = "LOGIN_USER",
    UPDATE_USER = "UPDATE_USER",
    LOGOUT_USER = "LOGOUT_USER"
}

export interface actionLogin {
    type: ActionType.LOGIN_USER;
    payload: payloadData;
}

export interface actionUpdate {
    type: ActionType.UPDATE_USER;
    payload: payloadData;
}

export interface actionLogout {
    type: ActionType.LOGOUT_USER;
}

export type Action = actionLogin | actionLogout | actionUpdate;