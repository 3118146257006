import axios from "axios";
import { API_URL } from "../constant/constant";
import { toast } from "react-toastify";
import { generalObj } from "../constant/object_types";

export const checkDeviceDesktop = () => {
  const expression = /(Mac|MacIntel|Win32|Linux x86_64|Linux armv81)/i;

  if (expression.test(navigator.platform)) {
    return true;
  } else {
    return false;
  }
}

export const objectToQueryString = (obj:generalObj) => {
  const keys = Object.keys(obj);
  const keyValuePairs = keys.map(key => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(obj[key]);
  });
  return keyValuePairs.join('&');
}

export const getBlobUri = (val:Blob) => {
  const url = URL.createObjectURL(val);
  return url
}

export const encryptPhone = (val:string) => {
  let start_text = val.slice(0, 3);
  let end_text = val.slice(val.length - 4, val.length-1);
  return `${start_text}xxxxxx${end_text}`
}

export const encryptEmail = (val:string) => {
  let start_text = val.slice(0, 3);
  let end_text = val.split("@")[1];
  return `${start_text}xxxxxx@${end_text}`
}

export const dateStr = (dateObj:string) => {
  let new_date:Date = new Date(dateObj);
  let date_string = `${new_date.getDate()}/${new_date.getMonth() + 1}/${new_date.getFullYear()}`
  return date_string
}

export const timeStr = (dateObj:string) => {
  let new_date:Date = new Date(dateObj);
  return `${new_date.getHours()}:${new_date.getMinutes()}`;
}

/* API Based Function */

export const getCSVObject = async (url:String) => {
  let config = {
    method: "GET",
    url: `${url}`,
    headers: {
      'Content-Type': 'application/csv',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching CSV")
    }
    return false;
  })

  return result;
}

export const fetchCategoryUnitList = async (token:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}product/category_unit/list/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching category")
    }
    return false;
  })

  return result;
}

export const add_editProduct = async (token:string, data:generalObj) => {
  let formData = new FormData();
  let new_Date = new Date();
  let filename = `${data["name"].toString().slice(0, 3)}-${new_Date.getTime()}.png`;
  for (let key in data) {
    if (key === 'image' && data[key]) {
      formData.append("image", data[key], filename);

    }else if (key === 'store') {
      data[key].forEach((element:string) => {
        formData.append(`${key}[]`, element);
      });
    }else {
      if (data[key] !== null) {
        formData.append(key, typeof(data[key]) === 'boolean' ? data[key] ? 1 : 0 : data[key]);
      }
    }
  }

  let config = {
    method: 'post',
    url:`${API_URL}product/create/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'multipart/form-data'
    },
    data: formData
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const fetchProductList = async (token:string, params:generalObj|null = null) => {
  let api_url = `${API_URL}product/list/`;
  
  if (params) {
    api_url = `${api_url}?${objectToQueryString(params)}`;
  }

  let config = {
    method: "GET",
    url: api_url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching products")
    }
    return false;
  })

  return result;
}

export const fetchProductListBySearch = async (token:string, name:string, params:generalObj|null = null) => {
  let api_url = `${API_URL}product/search/${name}/`;
  
  if (params) {
    api_url = `${api_url}?${objectToQueryString(params)}`;
  }

  let config = {
    method: "GET",
    url: api_url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching products")
    }
    return false;
  })

  return result;
}

export const fetchProductListById = async (token:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}product/list/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product")
    }
    return false;
  })

  return result;
}

export const deleteProductListById = async (token:string, id:string) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/delete/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error deleting product")
    }
    return false;
  })

  return result;
}

export const publishProductById = async (token:string, id:string) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/publish/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error updating product")
    }
    return false;
  })

  return result;
}

export const addCategory = async (token:string, data:generalObj) => {
  
  
  let config = {
    method: 'post',
    url:`${API_URL}product/category/add/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const getOrCreateCatUnit = async (token:string, data:generalObj) => {
  
  
  let config = {
    method: 'post',
    url:`${API_URL}product/category_unit/get_or_create/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    return false;
  });

  return result
}

export const addUnit = async (token:string, data:generalObj) => {
  
  
  let config = {
    method: 'post',
    url:`${API_URL}product/unit/add/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const addUser = async (token:string, data:generalObj, method:string = "post") => {
  
  let config = {
    method: method,
    url:`${API_URL}account/user/create/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const restrictUser = async (token:string, id:string) => {
  
  let config = {
    method: "post",
    url:`${API_URL}account/user/restrict/${id}/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const deleteUser = async (token:string, id:string) => {
  
  let config = {
    method: "delete",
    url:`${API_URL}account/user/delete/${id}/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const fetchUserList = async (token:string, type:string="all") => {
  
  let config = {
    method: 'get',
    url:`${API_URL}account/user/list/${type}/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && (error.response.data.message || error.response.data.detail)) {
      toast(error.response.data.message ? error.response.data.message : error.response.data.detail);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const fetchUserListById = async (token:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}account/user/get/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user data")
    }
    return false;
  })

  return result;
}

export const fetchUserListByStoreId = async (token:string, id:string, role:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}account/user/store/list/${id}/${role}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user data")
    }
    return false;
  })

  return result;
}

export const fetchOrganizationList = async (token:string) => {
  
  let config = {
    method: 'get',
    url:`${API_URL}account/organization/list/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const fetchStoreList = async (token:string) => {
  
  let config = {
    method: 'get',
    url:`${API_URL}account/store/list/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    }
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const fetchStoreListById = async (token:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}account/store/list/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching store")
    }
    return false;
  })

  return result;
}


export const fetchStoreListByUserId = async (token:string, id:string, type:string="All") => {
  let config = {
    method: "GET",
    url: `${API_URL}account/store/user/list/${id}/${type}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching store")
    }
    return false;
  })

  return result;
}

export const deleteStoreListById = async (token:string, id:string) => {
  let config = {
    method: "POST",
    url: `${API_URL}account/store/delete/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error deleting store")
    }
    return false;
  })

  return result;
}

export const addEditOrganization = async (token:string, data:generalObj) => {
  
  let config = {
    method: 'post',
    url:`${API_URL}account/organization/create/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}

export const addEditStore = async (token:string, data:generalObj) => {
  
  let config = {
    method: 'post',
    url:`${API_URL}account/store/create/`,
    headers: { 
      'Authorization': `Bearer ${token}`, 
      'Content-Type': 'application/json'
    },
    data: data
  }

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Unable to process your request, kindly try again later");
    }
    return false;
  });

  return result
}


export const fetchDashboard = async (token:string) => {
  let config = {
    method: "get",
    url: `${API_URL}dashboard/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };
  
  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching dashboard data")
    }
    return false;
  })

  return result;
}

export const checkStoreByStoreIdUserId = async (token:string, store_id:string, user_id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}account/user/check/${store_id}/${user_id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching result")
    }
    return false;
  })

  return result;
}

export const uploadStockOrder = async (token:string, data:generalObj) => {
  let formData = new FormData();
  for (let key in data) {
    if (key === 'stock_file' && data[key]) {
      formData.append("stock_file", data[key]);

    }else {
      if (data[key] !== null) {
        formData.append(key, typeof(data[key]) === 'boolean' ? data[key] ? 1 : 0 : data[key]);
      }
    }
  }

  let config = {
    method: "POST",
    url: `${API_URL}product/stock/create_order/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: formData
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error uploading stock order")
    }
    return false;
  })

  return result;
}


export const fetchStockOrder = async (token:string, store_id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}product/stock/list/?store_id=${store_id}`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching stock order")
    }
    return false;
  })

  return result;
}

export const updateStockOrder = async (token:string, data:object) => {
  let config = {
    method: "PUT",
    url: `${API_URL}product/stock/update/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    toast(resp.data.message);
    return true
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error updating stock order")
    }
    return false;
  })

  return result;
}

export const createProductOrder = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/order/create_product_order/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error creating product order")
    }
    return false;
  })

  return result;
}

export const deleteProductOrder = async (token:string, data:object) => {
  let config = {
    method: "DELETE",
    url: `${API_URL}product/order/create_product_order/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error deleting product order")
    }
    return false;
  })

  return result;
}

export const createStocking = async (token:string, data:object, method?:string) => {
  let config = {
    method: method ? method : "POST",
    url: `${API_URL}product/order/create_update_stocking/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error creating stocking order")
    }
    return false;
  })

  return result;
}

export const createOrderReceived = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/order/create_order_received/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error creating order received")
    }
    return false;
  })

  return result;
}

export const createExpiry = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/order/create_product_expiry/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error creating expiry")
    }
    return false;
  })

  return result;
}

export const updateExpiry = async (token:string, data:object) => {
  let config = {
    method: "PUT",
    url: `${API_URL}product/order/create_product_expiry/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error updating expiry")
    }
    return false;
  })

  return result;
}

export const createReturns = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/order/create_product_return/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error creating adding returns")
    }
    return false;
  })

  return result;
}

export const updateProductOrder = async (token:string, data:object) => {
  let config = {
    method: "PUT",
    url: `${API_URL}product/order/create_product_order/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.message
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error updating product order")
    }
    return false;
  })

  return result;
}

export const fetchProductParentOrder = async (token:string, store_id:string, id:string, range?:string|null) => {
  let url;
  if (range) {
    url = `${API_URL}product/order/list/product_parent/${store_id}/${id}/?range=${range}`
  }else {
    url = `${API_URL}product/order/list/product_parent/${store_id}/${id}/`
  }
  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product order")
    }
    return false;
  })

  return result;
}

export const fetchProductParentSubOrder = async (token:string, store_id:string, order_id:string, id:string, range?:string|null) => {
  let url;
  if (range) {
    url = `${API_URL}product/order/list/product_order/${store_id}/${id}/${order_id}/?range=${range}`
  }else {
    url = `${API_URL}product/order/list/product_order/${store_id}/${id}/${order_id}/`
  }
  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product order")
    }
    return false;
  })

  return result;
}

export const deleteProductSubOrder = async (token:string, store_id:string, order_id:string, child_id:string, id:string) => {
  let config = {
    method: "DELETE",
    url: `${API_URL}product/order/delete/product_order/${store_id}/${id}/${order_id}/${child_id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error deleting product order")
    }
    return false;
  })

  return result;
}

export const fetchProductSubOrderById = async (token:string, store_id:string, order_id:string, order_child:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}product/order/get/product_order/${store_id}/${id}/${order_id}/${order_child}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product order")
    }
    return false;
  })

  return result;
}

export const fetchTicketRaised = async (token:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}tickets/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching tickets")
    }
    return false;
  })

  return result;
}

export const fetchTicketRaisedById = async (token:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}tickets/get/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching ticket")
    }
    return false;
  })

  return result;
}

export const raiseTicket = async (token:string, method:string="POST", data:object) => {
  let config = {
    method: method,
    url: `${API_URL}tickets/raise/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error raising tickets")
    }
    return false;
  })

  return result;
}

export const fetchMessagesSent = async (token:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}message/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching messages")
    }
    return false;
  })

  return result;
}

export const fetchMessageById = async (token:string, id:string) => {
  let config = {
    method: "GET",
    url: `${API_URL}message/get/${id}/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching message")
    }
    return false;
  })

  return result;
}

export const newMessage = async (token:string, method:string="POST", data:object) => {
  let config = {
    method: method,
    url: `${API_URL}message/raise/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error sending message")
    }
    return false;
  })

  return result;
}

export const fetchExpiryParentProduct = async (token:string, store_id?:string, vendor_id?:string) => {
  let url;
  if (store_id && vendor_id) {
    url = `${API_URL}product/expiry/list_parent/?store=${store_id}&vendor=${vendor_id}`; 
  }else if (store_id) {
    url = `${API_URL}product/expiry/list_parent/?store=${store_id}`; 
  }else {
    url = `${API_URL}product/expiry/list_parent/`; 
  }

  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching expiring product")
    }
    return false;
  })

  return result;
}

export const fetchExpiryProduct = async (token:string, store_id?:string, vendor_id?:string, parent_id?:string) => {
  let url;
  if (store_id && vendor_id) {
    url = `${API_URL}product/expiry/list/?store=${store_id}&vendor=${vendor_id}&parent=${parent_id}`; 
  }else if (store_id) {
    url = `${API_URL}product/expiry/list/?store=${store_id}&parent=${parent_id}`; 
  }else {
    url = `${API_URL}product/expiry/list/?parent=${parent_id}`; 
  }

  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching expiring product")
    }
    return false;
  })

  return result;
}

export const sendExpiryEmail = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/expiry/sendmail/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data:data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error send expiring product email")
    }
    return false;
  })

  return result;
}

export const sendDamageEmail = async (token:string, data:object) => {
  let config = {
    method: "POST",
    url: `${API_URL}product/damage/sendmail/`,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data:data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error send expiring product email")
    }
    return false;
  })

  return result;
}

export const fetchDamageProduct = async (token:string, store_id?:string, vendor_id?:string) => {
  let url;
  if (store_id && vendor_id) {
    url = `${API_URL}product/damage/list/?store=${store_id}&vendor=${vendor_id}`; 
  }else if (store_id) {
    url = `${API_URL}product/damage/list/?store=${store_id}`; 
  }else {
    url = `${API_URL}product/damage/list/` 
  }
  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching damaged & returned products")
    }
    return false;
  })

  return result;
}

export const fetchDamageProductChild = async (token:string, store_id?:string, vendor_id?:string, product_id?:string) => {
  let url;
  if (store_id && vendor_id) {
    url = `${API_URL}product/damage_child/list/?store=${store_id}&vendor=${vendor_id}&product=${product_id}`; 
  }else if (store_id) {
    url = `${API_URL}product/damage_child/list/?store=${store_id}&product=${product_id}`; 
  }else {
    url = `${API_URL}product/damage_child/list/?product=${product_id}` 
  }
  let config = {
    method: "GET",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    }
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching damaged & returned products")
    }
    return false;
  })

  return result;
}

export const updateDamageProductChild = async (token:string, data:object) => {
  let url = `${API_URL}product/damage_child/list/`
  let config = {
    method: "PUT",
    url: url,
    headers: {  
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    data: data
  };

  let result = await axios(config)
  .then((resp) => {
    return resp.data
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error updating damaged products")
    }
    return false;
  })

  return result;
}

export const fetchProductListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/product_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product List report")
    }
    return false;
  })

  return result;
}

export const fetchStoreListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/store_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching store List report")
    }
    return false;
  })

  return result;
}

export const fetchStoreGeneralListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/store_general_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching general store List report")
    }
    return false;
  })

  return result;
}

export const fetchUserListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user List report")
    }
    return false;
  })

  return result;
}

export const fetchProductOrderParentListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_orderlist/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user List report")
    }
    return false;
  })

  return result;
}

export const fetchProductOrderSubListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_sub_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user List report")
    }
    return false;
  })

  return result;
}

export const fetchProductExpListParentReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_exp_list_parent/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product expiry List report")
    }
    return false;
  })

  return result;
}

export const fetchProductExpListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_exp_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching product expiry List report")
    }
    return false;
  })

  return result;
}

export const fetchProductDmgListReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_dmg_list/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching user List report")
    }
    return false;
  })

  return result;
}

export const fetchProductDmgListChildReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_dmg_list_child/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching damage List report")
    }
    return false;
  })

  return result;
}

export const fetchProductDmgListReturnReport = async (token:string, param_obj:object) => {
  let url;
  let param = objectToQueryString(param_obj)
  url = `${API_URL}dashboard/report/user_product_dmg_list_return/?${param}` 
  let config = {
    method: "GET",
    headers: {  
      'Authorization': `Bearer ${token}`,
    }
  };
  let result = await fetch(url, config)
  .then(async (res) => {
      let data_obj = await res.blob();
      if (data_obj.type === "application/json") {
        let msg_error = await data_obj.text();
        toast(JSON.parse(msg_error).message);
        return false
      }else {
        return {
          data: data_obj
        }
      }
  })
  .catch((error) => {
    if (error.response && error.response.data.message) {
      toast(error.response.data.message);
    }else {
      toast("Error fetching damage List report")
    }
    return false;
  })

  return result;
}