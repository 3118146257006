import { useEffect, useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext } from "react-router-dom";
import { updateProductOrder } from "../../action/generalFunc";
import { toast } from "react-toastify";

const UpdateSohModal = ({ visible, cur_soh, modalClose, onAdded }:{
    visible: boolean,
    cur_soh: generalObj,
    modalClose: () => void,
    onAdded: () => void,
}) => {

    const [formData, setFormData] = useState<generalObj>({
        soh: "",
        product_id: null
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    useEffect(() => {
        if (!account || !account.isAuthenticated || !cur_soh) return;
        setFormData({
            soh: cur_soh.quantity,
            product_id: cur_soh.id
        });
    }, [account, cur_soh]);

    const submitSoh = async () => {
        if (!account || !account.isAuthenticated || !cur_soh) return;
        if (formData.soh.length === 0) {
            toast("SOH is required");
            return;
        }
        if (parseInt(formData.soh) < 0) {
            toast("SOH quantity is not a number");
            return;
        }
        if (!formData.product_id) {
            toast("Product info is required");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            product_order_id: formData.product_id,
            soh: formData.soh
        };

        let result = await updateProductOrder(account.token, api_data);
        if (result) {
            toast(result);
            setTimeout(() => {
                onAdded();
            }, 1500);
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Update Product Order</h4>
                    <div>
                        <InputField label={"SOH"} inputProp={{value:formData.soh, placeholder:"Enter SOH", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("soh", evt)} containerStyle={{width:"100%"}} />
                                        
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => submitSoh()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}
export default UpdateSohModal;