import { Outlet, useNavigate, useParams } from "react-router-dom";
import { ModalLoader, ParentContainer } from "../component";
import { useEffect, useState } from "react";
import { UrlTypes } from "../constant/object_types";
import { useSelector, useDispatch } from "react-redux";
import { RootState, actionCreator } from "../redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";

const Home = () => {
    const [modalLoading, setModalLoading] = useState<boolean>(true);
    const [modelMessage, setModalMessage] = useState<string|JSX.Element>("");
    const  { account_type } = useParams();
    const navigate = useNavigate();
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { logout } = bindActionCreators(actionCreator, dispatch);

    // useEffect(() => {
    //     let result = checkDeviceDesktop();
    //     if (!result) {
    //         setIsDesktop(false);
    //     }
    // }, []);

    useEffect(() => {
        if (!account_type) return;
        switch (account_type) {
            case "admin":
                console.log("Admin");
                break;
            case "staff":
                console.log("Staff");
                break;
            case "vendor":
                console.log("Vendor");
                break;
            default:
                navigate(UrlTypes.ERRORPAGE);
                break;
        }
    }, [account_type, navigate]);

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        const refreshTimer = setInterval(async () => {
            let now = new Date();
            let end_time = new Date(account.expiry)
            if (now.getTime() < end_time.getTime()) return;
            await logout();
            toast('Login session has expired, kindly login again');
        }, 60000);
        return () => clearInterval(refreshTimer);
    }, [account, logout]);

    return (
        <>
            <ParentContainer>
                <Outlet context={{setModalLoading, setModalMessage}} />
            </ParentContainer>
            <ModalLoader visible={modalLoading} message={modelMessage} />
        </>
    )
}

export default Home;