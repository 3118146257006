import { IoAdd } from "react-icons/io5";
import { CardSearch, ConfirmationModal, ExportData, PageBodyHeader, ReportModal, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { DeleteButtonSvg, EditButtonSvg } from "../../style/iconStyle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { deleteProductListById, fetchProductList, fetchProductListReport, publishProductById } from "../../action/generalFunc";
import { FiDownload, FiUpload } from "react-icons/fi";
import { toast } from "react-toastify";

const ProductList = () => {
    const navigate = useNavigate();
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [itemToDelete, setItemToDelete] = useState<generalObj | null>(null);
    const [itemToPub, setItemToPub] = useState<generalObj | null>(null);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [openPubConfirmation, setOpenPubConfirmation] = useState<boolean>(false);
    const [openReportModal, setOpenReportModal] = useState<boolean>(false);
    const [exportType, setExportType] = useState<string | null>(null);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchProductList(account.token);
            if (result) {
                setProductList(result.data.product);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const selectItemForDelete = (id:number|string) => {
        let item_obj = productList.filter((item) => item.id === id);

        if (item_obj.length === 0) return;
        setItemToDelete(item_obj[0]);
        setOpenConfirmation(true);
    }

    const selectItemForPub = (id:number|string) => {
        let item_obj = productList.filter((item) => item.id === id);

        if (item_obj.length === 0) return;
        setItemToPub(item_obj[0]);
        setOpenPubConfirmation(true);
    }

    const onDelete = async () => {
        if (!account || !itemToDelete) return;
        setModalLoading(true);
        let result = await deleteProductListById(account.token, itemToDelete.id);
        if (result) {
            toast(result.message);
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => item.id !== itemToDelete.id)
                )
            });
        }
        setItemToDelete(null);
        setOpenConfirmation(false)
        setModalLoading(false)
    }

    const onPublish = async () => {
        if (!account || !itemToPub) return;
        setModalLoading(true);
        let result = await publishProductById(account.token, itemToPub.id);
        if (result) {
            toast(result.message);
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.id === itemToPub.id) {
                            item.published = result.data.publish;
                        }
                        return item;
                    })
                )
            });
        }
        setItemToPub(null);
        setOpenPubConfirmation(false)
        setModalLoading(false)
    }

    const processExport = (export_type:string) => {
        setExportType(export_type);
        setOpenReportModal(true);
    }

    const onSearch = (val:string) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || item.size.toLowerCase().includes(val.toLowerCase())  || (item.unit && item.unit.toLowerCase().includes(val.toLowerCase())) || (item.vendor && item.vendor.toLowerCase().includes(val.toLowerCase()))) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const generateReport = async (val:generalObj) => {
        if (!exportType || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let date_from_obj = new Date(val.date_from);
        let date_to_obj = new Date(val.date_to);
        let param = {
            type: exportType,
            date_from: date_from_obj.toISOString(),
            date_to: date_to_obj.toISOString()
        };
        
        let result = await fetchProductListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            setExportType(null);
            setOpenReportModal(false);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Product List" desc="Manage your products">
                <button className="product_create_button" onClick={() => navigate(UrlTypes.ADMIN_PRODUCT_ADD)}>
                    <span><IoAdd /></span>
                    <span>Create Product</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["Product name", "Size", "Vendor", "Stores", "isPublished", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>
                                    <div className="contentWithImg">
                                        <span><img src={item.image} alt={item.name} /></span>
                                        <span>{item.name}</span>
                                    </div>
                                </td>
                                <td>{item.size}{item.unit}</td>
                                <td>{item.vendor}</td>
                                <td>{item.store.length}</td>
                                <td><div className={`status__container ${item.published ? "completed" : "pending"}`} style={{width:"80px", padding:"5px"}}>{item.published ? "True" : "False"}</div></td>
                                <td>
                                    <div className="edit__delete_action">
                                        <button title={item.published ? "Unpublish" : "Publish"} onClick={() => selectItemForPub(item.id)}>
                                            <span>
                                                {item.published ? <FiDownload size={24} color="#EF5F0F" /> : <FiUpload size={24} color="#EF5F0F" />}
                                            </span>
                                        </button>
                                        <button onClick={() => navigate(`${UrlTypes.ADMIN_PRODUCT_EDIT}${item.id}/`)}>
                                            <span>
                                                <EditButtonSvg />
                                            </span>
                                        </button>

                                        <button onClick={() => selectItemForDelete(item.id)}>
                                            <span>
                                                <DeleteButtonSvg />
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            {itemToDelete && <ConfirmationModal visible={openConfirmation} title={"Delete Confirmation"} description={`Do you want to delete ${itemToDelete.name}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToDelete(null); setOpenConfirmation(false)}} onSubmit={() => onDelete()} />}
            {itemToPub && <ConfirmationModal visible={openPubConfirmation} title={`${itemToPub.published ? "Unpublish" : "Publish"} Confirmation`} description={`Do you want to ${itemToPub.published ? "unpublish" : "publish"} ${itemToPub.name}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToPub(null); setOpenPubConfirmation(false)}} onSubmit={() => onPublish()} />}
            <ReportModal visible={openReportModal} modalClose={() => setOpenReportModal(false)} onAdded={(val) => generateReport(val)}  />
            
        </>
    )
}

export default ProductList;