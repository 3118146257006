import { ActionDropDown, ButtonFrame, CardSearch, CustomCheckBox, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchExpiryParentProduct, fetchProductExpListParentReport, fetchUserListById, sendExpiryEmail } from "../../action/generalFunc";
import { toast } from "react-toastify";

const StaffProductExpiry = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productNextList, setProductNextList] = useState<generalObj[]>([]);
    const [inputChecked, setInputChecked] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");
    const [searchNextText, setSearchNextText] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { id, store_id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            setModalLoading(true);
            let result = await fetchUserListById(account.token, id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let expiry_result = await fetchExpiryParentProduct(account.token, store_id, id)
                if (!expiry_result) {
                    window.history.back();
                }else {
                    setProductList(expiry_result.products);
                    setProductNextList(expiry_result.products_next);
                    setStoreName(`${expiry_result.store_data.name} - ${expiry_result.store_data.organization}`)
                    setVendorName(`${result.data.user.username}`)
                }
                setModalLoading(false);
            })();
        })();
    }, [account, id, store_id, setModalLoading]);

    const changeChecked = (id:number, value:boolean | null = null) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.expiry_id === id) {
                        item.isChecked = value === null ? !item.isChecked : value;
                    }
                    return item;
                })
            )
        })
    }

    const checkAll = () => {
        let new_check = !inputChecked;
        
        productList.forEach(element => {
            changeChecked(element.expiry_id, new_check);
        });
        
        setInputChecked(new_check);
    }

    const processExport = (export_type:string, expiry:string) => {
        generateReport(export_type, expiry);
    }

    const onSearch = (val:string, expiring:boolean=true) => {
        if (expiring) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductNextList((prevProductNextList) => {
                return (
                    prevProductNextList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchNextText(val);
        }
        
    }

    const generateReport = async (exportType:string, expiry:string) => {
        if (!exportType || !account || !account.isAuthenticated || !id || !store_id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            expire_type:expiry,
            store: store_id,
            vendor: id
        };
        
        let result = await fetchProductExpListParentReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_expiry_staff.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    const sendNotification = async (id:number, bulk?:boolean) => {
        if (!account || !account.isAuthenticated) return;

        setModalLoading(true);
        let result = await sendExpiryEmail(account.token, {
            expiry: id
        })

        if (result) {
            if (bulk) {
                return true;
            }else {
                toast(result.message);
                window.location.reload();
            }
        }else {
            if (bulk) {
                return false;
            }else {
                toast(result)
            }
        }
        setModalLoading(false);
    }

    const sendBulkEmail = () => {
        if (!account || !account.isAuthenticated) return;

        let selected_item = productList.filter((item) => item.isChecked && !item.notif);

        if (selected_item.length === 0) {
            toast("No expiry item selected");
            return;
        }
        
        setModalLoading(true);
        let total_ids:Array<number> = [];

        selected_item.forEach(async (eItem) => {
            eItem.not_sent.forEach((elem:number) => {
                total_ids.push(elem);
            })
        })

        total_ids.forEach(async (tItem, index) => {
            await sendNotification(tItem, true)

            if (index+1 === total_ids.length) {
                window.location.reload();
                setModalLoading(false);
            }
        })
    }

    const sendCollectionEmail = (items:Array<number>) => {
        if (!account || !account.isAuthenticated) return;
        
        setModalLoading(true);
        items.forEach(async (eItem, index) => {
            await sendNotification(eItem, true)

            if (index+1 === items.length) {
                window.location.reload();
                setModalLoading(false);
            }
        })
    }

    return (
        <>
            <PageBodyHeader title="About to Expire Product" desc="Checklist of about to expire product">
                <ButtonFrame btn_text="Send notifications" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#eb212f", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => sendBulkEmail()} />      
            </PageBodyHeader>

            <div>
                <Table table_head={[{
                    element: <CustomCheckBox checked={inputChecked} onChange={() => checkAll()} />
                }, "Product name", "Size", "Quantity", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
                tableAction={
                    <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                        <ExportData export_type={(val) => processExport(val, "under")} />
                        <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>
                }
                headerStyle={{marginBottom: "44px"}}
                table_title={`About to expire products for ${vendorName.toUpperCase()} in ${storeName}`}
                >
                    <>
                        {productList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>
                                        <CustomCheckBox checked={item.isChecked} onChange={() => changeChecked(item.expiry_id, !item.isChecked)} />
                                    </td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <div className={`status__container ${item.not_sent.length === 0 ? "completed" : "notsent"}`} onClick={() => item.not_sent.length > 0 && sendCollectionEmail(item.not_sent)} style={{width:"150px", padding:"8px", fontWeight:"600",cursor:"pointer"}}>Send notifications</div>
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_EXPIRY_SUB}${id}/${store_id}/${item.id}?expiring=true`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>

            <div style={{marginTop:"32px"}}>
                <Table table_head={["Product name", "Size", "Quantity", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchNextText} onTextChange={(evt) => onSearch(evt, false)} />}
                headerStyle={{marginBottom: "44px"}}
                tableAction={
                    <div style={{display:"flex", gap:"20px", alignItems:'center'}}>
                        <ExportData export_type={(val) => processExport(val, "over")} />
                    </div>
                }
                table_title={`Products and their expiry for ${vendorName.toUpperCase()} in ${storeName}`}
                >
                    <>
                        {productNextList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_EXPIRY_SUB}${id}/${store_id}/${item.id}`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>
        </>
    )
}

export default StaffProductExpiry;