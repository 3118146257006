import { ButtonFrame, Card, PageBodyHeader } from "../../component";
import { useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useState } from "react";
import { fetchStockOrder, fetchStoreListById } from "../../action/generalFunc";
import { DownloadSvg } from "../../style/iconStyle";
import { generalObj } from "../../constant/object_types";

const VendorStockOrder = () => {
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const [invoiceList, setInvoiceList] = useState<generalObj[]>([]);
    const [storeName, setStoreName] = useState<string>("");
    const account = useSelector((state:RootState) => state.account);
    const { id } = useParams();     
    
    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            let result = await fetchStoreListById(account.token, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store.name} - ${result.data.store.organization}`);
                (async () => {
                    let store_result = await fetchStockOrder(account.token, id);
                    if (!store_result) {
                        window.history.back();
                    }else {
                        setInvoiceList(store_result.stock);
                    }
                })();
            }
            setModalLoading(false)
        })();
    }, [account, setModalLoading, id]);

    const downloadSingle = async (url:string, name:string) => {
        setModalLoading(true);
          
        await fetch(`${url}?v=1`)
        .then(res => res.blob())
        .then(res => {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `${name}.png`);
            const href = URL.createObjectURL(res);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
        });
        setModalLoading(false);
    }

    const downloadMultiple = async () => {
        invoiceList.forEach(async (elem) => {
            await downloadSingle(elem.file, elem.name);
        })
    }

    return (
        <>
            <PageBodyHeader title="Download Stock Orders" desc="Download Orders made from Store" />
            
            <h3 style={{marginBottom:"18px", textAlign:"center"}}>{`Stock Order for ${storeName} store`}</h3>
            
            <Card title="Order List" style={{height:"100%"}}>
                <div>
                    {invoiceList.map((item, index) => (
                        <button key={index} className="component_menu" style={{display:"flex", justifyContent:"space-between", alignItems:"center"}} onClick={() => downloadSingle(item.file, item.name)}>
                            <span>{item.name}</span>
                            <span style={{display:"flex", width:"20px", height:"20px"}}><DownloadSvg /></span>
                        </button>
                    ))}

                    <div style={{marginTop:"85px", display:"flex", gap:"24px"}}>
                        {invoiceList.length > 0 && <ButtonFrame btn_text="Download All" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => downloadMultiple()} /> }
                        <ButtonFrame btn_text="Back" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#676967", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} /> 
                    </div>
                </div>
            </Card>
        </>
    )
}

export default VendorStockOrder;