import { ButtonFrame, Card, PageBodyHeader } from "../../component";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useState } from "react";
import { fetchStoreListById } from "../../action/generalFunc";
import { UrlTypes } from "../../constant/object_types";

const VendorMenu = () => {
    const navigate = useNavigate();
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const [storeName, setStoreName] = useState<string>("");
    const { id } = useParams();     
    
    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            let result = await fetchStoreListById(account.token, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store.name} - ${result.data.store.organization}`);
            }
            setModalLoading(false)
        })();
    }, [account, setModalLoading, id]);

    return (
        <>
            <PageBodyHeader title="Store" desc="Store Components" />
            <h3 style={{marginBottom:"18px", textAlign:"center"}}>{`Menu for ${storeName} store`}</h3>

            <Card style={{height:"100%"}}>
                <div>
                    <button onClick={() => navigate(`${UrlTypes.VENDOR_STOCK_ORDER}${id}/`)} className="component_menu">Stock Order</button>
                    <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_LIST}${id}/`)} className="component_menu">Product List</button>
                    <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_EXPIRY}${id}/`)} className="component_menu">Expiry</button>
                    <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_DAMAGED}${id}/`)} className="component_menu">Damage & Returns</button>
                </div>
            </Card>
            <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.VENDOR_STORE_LIST}`)} />
            </div>
        </>
    )
}

export default VendorMenu;