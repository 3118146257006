export type generalObj = {
    [key:string]: any
};

export enum UrlTypes {
    HOME = "/",
    ADMIN_DASHBOARD = "/admin/dashboard/",
    ADMIN_PRODUCT_LIST = "/admin/product/",
    ADMIN_PRODUCT_EXPIRING = "/admin/expiring_product/",
    ADMIN_PRODUCT_EXPIRING_SUB = "/admin/expiring_product_sub/",
    ADMIN_PRODUCT_DAMAGED = "/admin/damaged_product/",
    ADMIN_PRODUCT_DAMAGED_CHILD = "/admin/damaged_product_child/",
    ADMIN_PRODUCT_ADD = "/admin/create_product/",
    ADMIN_PRODUCT_EDIT = "/admin/create_product/edit/",
    ADMIN_PRODUCT_IMPORT = "/admin/import_product/",
    ADMIN_VENDOR_LIST = "/admin/vendor/",
    ADMIN_VENDOR_ADD = "/admin/vendor/add/",
    ADMIN_VENDOR_EDIT = "/admin/vendor/edit/",
    ADMIN_STAFF_LIST = "/admin/staff/",
    ADMIN_STAFF_ADD = "/admin/staff/add/",
    ADMIN_STAFF_EDIT = "/admin/staff/edit/",
    ADMIN_STORE_LIST = "/admin/store/",
    ADMIN_STORE_ADD = "/admin/store/add/",
    ADMIN_STORE_EDIT = "/admin/store/edit/",
    ADMIN_MESSAGE_LIST = "/admin/message/",
    ADMIN_MESSAGE_ADD = "/admin/message/add/",
    ADMIN_MESSAGE_REPLY = "/admin/message/reply/",
    ADMIN_TICKET_LIST = "/admin/ticket/",
    ADMIN_PROFILE = "/admin/account/profile",

    STAFF_DASHBOARD = "/staff/dashboard/",
    STAFF_VENDOR_LIST = "/staff/vendor_list/",
    STAFF_STORE_LIST = "/staff/store_list/",
    STAFF_STORE_MENU = "/staff/store_menu/",
    STAFF_STOCK_ORDER = "/staff/purchase_order/",
    STAFF_PRODUCT_LIST = "/staff/product_list/",
    STAFF_PRODUCT_SUB_LIST = "/staff/product_sub_list/",
    STAFF_PRODUCT_ORDER = "/staff/product_order/",
    STAFF_PRODUCT_ORDER_EDIT = "/staff/product_order_edit/",
    STAFF_PRODUCT_EXPIRY = "/staff/product_expiry/",
    STAFF_PRODUCT_EXPIRY_SUB = "/staff/product_expiry_sub/",
    STAFF_PRODUCT_DAMAGED = "/staff/product_damaged/",
    STAFF_PRODUCT_DAMAGED_CHILD = "/staff/product_damaged_child/",
    STAFF_MESSAGE_LIST = "/staff/message/list/",
    STAFF_MESSAGE_ADD = "/staff/message/send/",
    STAFF_MESSAGE_EDIT = "/staff/message/send/edit/",
    STAFF_TICKET_LIST = "/staff/ticket/list/",
    STAFF_TICKET_REPLY = "/staff/ticket/edit/",
    STAFF_PROFILE = "/staff/account/profile",

    VENDOR_DASHBOARD = "/vendor/dashboard/",
    VENDOR_STORE_LIST = "/vendor/vendor_store_list/",
    VENDOR_MENU = "/vendor/vendor_menu/",
    VENDOR_STOCK_ORDER = "/vendor/vendor_stock_order/",
    VENDOR_PRODUCT_LIST = "/vendor/vendor_product_list/",
    VENDOR_PRODUCT_SUB_LIST = "/vendor/vendor_product_sub_list/",
    VENDOR_PRODUCT_EXPIRY = "/vendor/vendor_product_expiry/",
    VENDOR_PRODUCT_EXPIRY_SUB = "/vendor/vendor_product_expiry_sub/",
    VENDOR_PRODUCT_DAMAGED = "/vendor/vendor_product_damaged/",
    VENDOR_PRODUCT_DAMAGED_CHILD = "/vendor/vendor_product_damaged_child/",
    VENDOR_TICKET_LIST = "/vendor/ticket/vendor_list/",
    VENDOR_TICKET_RAISE = "/vendor/ticket/vendor_raise/",
    VENDOR_TICKET_REPLY = "/vendor/ticket/vendor_raise/edit/",
    VENDOR_PROFILE = "/vendor/account/profile",
    ERRORPAGE = "/404/"
}