import ModalParent from "./ModalParent";

const ModalLoader = ({ visible, message }:{
    visible: boolean,
    message?: string|JSX.Element 
}) => {
    return (
        <ModalParent visible={visible}>
            <div className="loadingView" style={{flexDirection:'column', gap:"40px"}}>
                <div>
                    <img src={require("../../assets/img/logo.png")} alt="loader" />
                </div>
                <p>{message}</p>
            </div>
        </ModalParent>
    )
}

export default ModalLoader;