import { useNavigate, useOutletContext } from "react-router-dom";
import DashboardChart from "./DashboardChart";
import Table from "../Table";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { ExpiredProductSvg, ProductSvg, StoreSvg, VendorSvg } from "../../style/iconStyle";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchDashboard, sendExpiryEmail, timeStr } from "../../action/generalFunc";
import QuickNotif from "../modal/QuickNotif";
import { toast } from "react-toastify";

const StaffDashboard = () => {

    const navigate = useNavigate();

    const [recentlyAdded, setRecentlyAdded] = useState<generalObj[]>([])

    const [expiringProduct, setExpiringProduct] = useState<generalObj[]>([]);

    const [tickets, setTickets] = useState<generalObj[]>([]);

    const [openQuickNotif, setOpenQuickNotif] = useState<boolean>(false);
    const [quickNotifProduct, setQuickNotifProduct] = useState<generalObj[]>([]);

    const [dashboardStat, setDashboardStat] = useState<generalObj>({
        vendor: 0,
        product: 0,
        expiry: 0,
        store: 0
    })
    const [chartData, setChartData] = useState<generalObj | null>(null);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchDashboard(account.token);
            if (result) {
                setDashboardStat({...result.data.stat});
                setTickets(result.data.tickets);
                setExpiringProduct(result.data.expiry);
                setChartData(result.data.chart_data);
                setRecentlyAdded(result.data.added_product)
                if (result.data.expiry_notif.length > 0) {
                    setOpenQuickNotif(true);
                    setQuickNotifProduct(result.data.expiry_notif)
                }
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const sendNotification = async (id:number, bulk?:boolean) => {
        if (!account || !account.isAuthenticated) return;

        setModalLoading(true);
        let result = await sendExpiryEmail(account.token, {
            expiry: id
        })

        if (result) {
            if (bulk) {
                return true;
            }else {
                toast(result.message);
                window.location.reload();
            }
        }else {
            if (bulk) {
                return false;
            }else {
                toast(result)
            }
        }
        setModalLoading(false);
    }

    const sendBulkEmail = () => {
        if (!account || !account.isAuthenticated) return;

        if (quickNotifProduct.length === 0) {
            toast("No expiry item selected");
            return;
        }
        
        setModalLoading(true);
        quickNotifProduct.forEach(async (eItem, index) => {
            await sendNotification(eItem.expiry_id, true)

            if (index+1 === quickNotifProduct.length) {
                window.location.reload();
                setModalLoading(false);
            }
        })
    }

    return (
        <>
            <div className="col__stats">
                <div className="col__statsItem" style={{backgroundColor:"#EF5F0F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.vendor}</span>
                        <span>Vendors</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <VendorSvg />
                    </div>
                </div>

                <div className="col__statsItem" style={{backgroundColor:"#1DCB5F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.product}</span>
                        <span>Products</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <ProductSvg />
                    </div>
                </div>

                <div className="col__statsItem" style={{backgroundColor:"#EB212F"}}>
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.expiry}</span>
                        <span>Expiry</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <ExpiredProductSvg />
                    </div>
                </div>

                <div className="col__statsItem">
                    <div className="col__statsItem__content">
                        <span>{dashboardStat.store}</span>
                        <span>Stores</span>
                    </div>
                    <div className="col__statsItem__icon">
                        <StoreSvg />
                    </div>
                </div>
            </div>

            <div className="col__summary">
                <div className="rateSale" style={{width:"65%"}}>
                    {chartData && <DashboardChart color="#1DCB5F" start_legend={"#B5E1C6"} chart_data={chartData} end_legend={"#45BA72"} style={{borderColor:"#B5E1C6"}} />}
                </div>
                <div style={{width:"35%"}}>
                    <Table title="Recently Ordered Products" table_head={["S/no", "Products", "Qty"]} th_class="neutralText" color={"#00983A"} thColor={"#B5E1C6"}>
                        <>
                            {recentlyAdded.map((item, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.quantity}</td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>

            <div className="col__summary">
                <div style={{width:"100%"}}>
                    <Table title="About to Expire Products" table_head={["S/no", "ID", "Product name", "Size", "Store", "Vendor", "Quantity", "Expiry Date "]} th_class="white" color={"#1159AB"} thColor={"#1159AB"}>
                        <>
                            {expiringProduct.map((item, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>#{item.expiry_id}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.store.name} - {item.store.organization}</td>
                                    <td>{item.vendor.username}</td>
                                    <td>{item.quantity}</td>
                                    <td>{dateStr(item.expiry_date)}</td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>


            <div className="col__summary">
                <div style={{width:"100%"}}>
                    <Table title="Tickets" table_head={["ID", "Subject", "Message", "Store", "Staff", "Last Update", "Status", "Action"]} th_class="white" color={"#45BA72"} thColor={"#00983A"}>
                        <>
                            {tickets.map((item, index) => (
                                <tr key={index}>
                                    <td>#{item.id}</td>
                                    <td>{item.subject}</td>
                                    <td>
                                        <div dangerouslySetInnerHTML={{__html: `${item.messages.message.slice(0, 38)}...`}} />
                                    </td>
                                    <td>{item.store.name} - {item.store.organization}</td>
                                    <td>{item.staff.username}</td>
                                    <td>{`${dateStr(item.messages.created_at)} (${timeStr(item.messages.created_at)})`}</td>
                                    <td>
                                        <div className={`status__container ${item.resolved ? "completed" : "pending"}`}>{item.resolved ? "Completed" : "Pending"}</div>
                                    </td>
                                    <td>
                                        <div className="edit__delete_action">
                                        
                                            <button onClick={() => navigate(`${UrlTypes.STAFF_TICKET_REPLY}${item.id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px", height:"auto"}}>
                                                {item.resolved ? "View" : "Reply"}
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </>
                    </Table>
                </div>
            </div>

            {quickNotifProduct.length > 0 && <QuickNotif visible={openQuickNotif} productList={quickNotifProduct} onSend={() => sendBulkEmail()} />}
        </>
    )
}

export default StaffDashboard;