import { IoEllipseSharp } from "react-icons/io5";
import Card from "../Card";
import BarChart from "../BarChart";
import ChartTimeFrameSelector from "./ChartTimeFrameSelector";
import { generalObj } from "../../constant/object_types";
import { useEffect, useState } from "react";


const DashboardChart = ({ start_legend, end_legend, chart_data, color, style }:{
    start_legend : string,
    end_legend: string,
    color: string,
    chart_data: generalObj,
    style ?: object
}) => {
    const [timeFrame, setTimeFrame] = useState<generalObj[]>([
        {
            id:1,
            name: "Weekly",
            value: "weekly",
            active: true
        },{
            id:2,
            name: "Monthly",
            value: "monthly",
            active: false
        },{
            id:3,
            name: "Yearly",
            value: "yearly",
            active: false
        }
    ])
    const [selectedData, setSelectedData] = useState<generalObj | null>(null);
    const [chartData, setChartData] = useState<generalObj | null>();
    useEffect(() => {
        if (!chart_data) return;
        let allFrame:generalObj[] = Object.entries(chart_data);
        let result_data:generalObj = {}
        allFrame.forEach((element) => {
            let selected_frame_all:generalObj[] = Object.entries(element[1]);
            let frame_label:Array<string> = []
            let frame_open:Array<number> = []
            let frame_close:Array<number> = []
            selected_frame_all.forEach((elem:generalObj) => {
                let elem_obj:generalObj = elem[1];
                frame_label.push(elem[0]);
                frame_open.push(elem_obj.opening);
                frame_close.push(elem_obj.closing);
            });
            result_data[element[0]] = {
                label: frame_label,
                opening_stock: frame_open,
                closing_stock: frame_close
            }
            if (element[0] === "weekly") {
                setSelectedData({
                    label: frame_label,
                    opening_stock: frame_open,
                    closing_stock: frame_close
                })
            }
        });
        setChartData(result_data);
    }, [chart_data]);

    const updateFrame = (id:number) => {
        if (!chartData) return;
        setTimeFrame((prevTimeFrame) => {
            return (
                prevTimeFrame.filter((item) => {
                    if (item.id === id) {
                        item.active = true;
                        setSelectedData(chartData[item.value])
                    }else {
                        item.active = false;
                    }
                    return true;
                })
            )
        });
    }
    return (
        <Card color={color} title="Rate of Sales" style={{height:"100%"}} cardAction={<ChartTimeFrameSelector timeFrame={timeFrame} onUpdateFrame={(id) => updateFrame(id)} />}>
            <div className="chartBody">
                <div className="chartLegend" style={style}>
                    <div>
                        <span><IoEllipseSharp size={12} color={start_legend} /></span>
                        <span>Opening Stock</span>
                    </div>
                    <div>
                        <span><IoEllipseSharp size={12} color={end_legend} /></span>
                        <span>Closing Stock</span>
                    </div>
                </div>
                <div style={{width: "80%"}}>
                    {selectedData && <BarChart bar_data={selectedData} start_color={start_legend} end_color={end_legend} />}
                </div>
            </div>
        </Card>
    )
}

export default DashboardChart;