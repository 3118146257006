import { ButtonFrame, Card, PageBodyHeader } from "../../component";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useEffect, useState } from "react";
import { checkStoreByStoreIdUserId } from "../../action/generalFunc";
import { UrlTypes } from "../../constant/object_types";

const StaffStoreMenu = () => {
    const navigate = useNavigate();
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");
    const account = useSelector((state:RootState) => state.account);
    const { id, store_id } = useParams();     
    
    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            let result = await checkStoreByStoreIdUserId(account.token, store_id, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
                setVendorName(`${result.data.vendor_data.username}`)
            }
            setModalLoading(false)
        })();
    }, [account, setModalLoading, id, store_id]);

    return (
        <>
            <PageBodyHeader title="Store" desc="Store Components" />
            <h3 style={{marginBottom:"18px", textAlign:"center"}}>{`Menu for ${vendorName.toLocaleUpperCase()} in ${storeName} store`}</h3>

            <Card style={{height:"100%"}}>
                <div>
                    <button onClick={() => navigate(`${UrlTypes.STAFF_STOCK_ORDER}${id}/${store_id}/`)} className="component_menu">Stock Order</button>
                    <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_ORDER}${id}/${store_id}/`)} className="component_menu">Product List</button>
                    <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_LIST}${id}/${store_id}/`)} className="component_menu">Product Report Entry</button>
                    <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_EXPIRY}${id}/${store_id}/`)} className="component_menu">Expiry</button>
                    <button onClick={() => navigate(`${UrlTypes.STAFF_PRODUCT_DAMAGED}${id}/${store_id}/`)} className="component_menu">Damage & Returns</button>
                </div>
            </Card>

            <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.STAFF_VENDOR_LIST}${store_id}/`)} />
            </div>
        </>
    )
}

export default StaffStoreMenu;