const ButtonFrame = ({btn_text, btn_containerStyle, btn_style, onPress}:{
    btn_text : string,
    btn_containerStyle ?: object,
    btn_style ?: object,
    onPress : () => void
}) => (
    <div style={btn_containerStyle}>
        <button style={btn_style} onClick={onPress}>
            <span>{btn_text}</span>
        </button>
    </div>
)

export default ButtonFrame;