import ModalParent from "./ModalParent";
import ButtonFrame from "../ButtonFrame";

const ConfirmationModal = ({ visible, title, description, closeText, submitText, modalClose, onSubmit }:{
    visible: boolean,
    title: string,
    description : string,
    closeText: string,
    submitText: string,
    modalClose: () => void,
    onSubmit : () => void
}) => {


    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>{title}</h4>
                    <p>{description}</p>
                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text={closeText} btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={modalClose} />
                        <ButtonFrame btn_text={submitText} btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={onSubmit}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default ConfirmationModal;