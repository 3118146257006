import { useState } from "react";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import InputDropdown from "../InputDropdown";

const ReportAdminModal = ({ visible, storeList, vendorList, modalClose, onAdded }:{
    visible: boolean,
    storeList: generalObj[],
    vendorList: generalObj[],
    modalClose: () => void,
    onAdded : (val:generalObj) => void
}) => {
    const [formData, setFormData] = useState<generalObj>({
        store: {
            label: 'All',
            value: ''
        },
        vendor: {
            label: 'All',
            value: ''
        }
    });

    const submit = async () => {
        onAdded(formData);
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Generate Report</h4>
                    <div>
                        <InputDropdown label={"Store"} data={storeList} placeHolder="Choose Store" selectedData={formData.store} containerClass={"mb-w-100"}  containerStyle={{width:"100%", marginBottom:"20px"}} onItemChange={(val) => setFormData({...formData, store:val})} />
                        <InputDropdown label={"Vendor"} data={vendorList} placeHolder="Choose Vendor" selectedData={formData.vendor} containerClass={"mb-w-100"}  containerStyle={{width:"100%", marginBottom:"20px"}} onItemChange={(val) => setFormData({...formData, vendor:val})} />
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Generate" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => submit()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default ReportAdminModal;