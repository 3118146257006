import { useEffect, useState } from "react";
import { AddOrganizationModal, ButtonFrame, Card, InputDropdown, InputField, PageBodyHeader } from "../../component"
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { addEditStore, fetchOrganizationList, fetchStoreListById } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IoAdd } from "react-icons/io5";

const AddStore = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        name: "",
        organization: null
    });
    const [organizationAdd, setOrganizationAdd] = useState<boolean>(false);

    const [organizationList, setOrganizationList] = useState<generalObj[]>([]);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext()

    const account = useSelector((state:RootState) => state.account);

    const { id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        setModalLoading(true);
        if (id) {
            (async () => {
                let result = await fetchStoreListById(account.token, id);
                if (result) {
                    setFormData({...result.data.store, organization:{
                        "label": result.data.store.organization,
                        "value": result.data.store.organization
                    }})
                }else {
                    navigate(UrlTypes.ADMIN_STORE_LIST);
                }
            })();
        }
        (async () => {

            let org_result = await fetchOrganizationList(account.token);
            if (org_result) {
                let org_list:generalObj[] = [];
                org_result.data.organization.forEach((element:generalObj) => {
                    org_list.push({
                        "id": element.id,
                        "label": element.name,
                        "value": element.name
                    })
                })
                setOrganizationList(org_list);
            }

            setModalLoading(false);
        })();
        setModalLoading(false)
    }, [account, id, setModalLoading, navigate]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const updateOrganizationList = (val:generalObj) => {
        setFormData((prevFormData) => ({...prevFormData, organization:val}));
    }

    const addStore = async () => {
        if (!account) return;
        if (formData.name.length === 0) {
            toast("Store name is required");
            return;
        }
        if (!formData.organization) {
            toast("Store organization is required");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            name: formData.name,
            organization: formData.organization.value
        };

        if (id) {
            api_data.edit = true;
            api_data.id = id;
        }

        let result = await addEditStore(account.token, api_data);
        if (result) {
            toast(result.message);
            navigate(UrlTypes.ADMIN_STORE_LIST);
        }
        setModalLoading(false);
    }

    const newOrganization = (val:generalObj) => {
        setOrganizationList((m) => [...m, val]);
        setOrganizationAdd(false);
    }

    return (
        <>
            <PageBodyHeader title="Add Store" desc="Add/Update Store" />

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div style={{display:'flex', flexWrap:'wrap', gap:"24px", alignItems:"center"}}>
                        <InputField label={"Store name"} inputProp={{value:formData.name}} onTextChange={(evt) => updateFormData("name", evt)} containerClass={"mb-w-100"} containerStyle={{width:"20%"}}  />
                        <InputDropdown label={"Organization"} data={organizationList} placeHolder="Choose Organization" selectedData={formData.organization} containerClass={"mb-w-100 mb-mt-24"}  containerStyle={{width:"20%"}} onItemChange={(val) => updateOrganizationList(val)} />
                        <div style={{width: "20%", display:"flex", alignItems:"center", gap:"10px", alignSelf:'flex-end', paddingBottom:"4px"}}>
                            <button className="product_add_button" style={{backgroundColor:"#EB212F", color:"#FFF", padding:"5px 12px", borderRadius:"5px"}} onClick={() => setOrganizationAdd(true)}>
                                <span style={{display:"flex"}}><IoAdd /></span>
                                <span>Add</span>
                            </button>
                        </div>
                    </div>

                    <div style={{marginTop:"140px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Save" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addStore()} />
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>

                </>
            </Card>

            <AddOrganizationModal visible={organizationAdd} modalClose={() => setOrganizationAdd(false)} onAdded={(val) => newOrganization(val)} />
        </>
    )
}

export default AddStore;