import { IoChevronDown } from "react-icons/io5";
import { FiChevronsRight } from "react-icons/fi";
import { AddProductSvg, DamagesSvg, DashboardSvg, ExpiredProductSvg, LogoutSvg, MessageIconSvg, MessageSvg, NotificationSvg, ProductSvg, ProfileUserSvg, StaffSvg, StoreSvg, TicketSvg, VendorSvg } from "../style/iconStyle";
import { useState } from "react";
import { UrlTypes } from "../constant/object_types";
import { bindActionCreators } from "redux";
import { RootState, actionCreator } from "../redux";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Navigation = ({ location, role, onExpand }:{
    location: string,
    role: string,
    onExpand: (val:boolean) => void
}) => {

    const [expandMenu, setExpandMenu] = useState<boolean>(false);
    const [openDrop, setOpenDrop] = useState<boolean>(false);
    const dispatch = useDispatch()
    const { logout } = bindActionCreators(actionCreator, dispatch);
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();

    const menuExpandToggle = () => {
        let new_val = !expandMenu;
        setExpandMenu(new_val);
        onExpand(new_val);
    }

    const callLogout = async () => {
        await logout();
    }

    return (
        <>
            <div className="top__Navbar">
                <div>
                    <div className="logo">
                        <img src={require("../assets/img/logo.png")} alt="logo" />
                    </div>
                    <button className={`chevron_close_open ${expandMenu ? "active" : ""}`} onClick={() => menuExpandToggle()}>
                        <FiChevronsRight size={16} color="#FFF" />
                    </button>

                    <div className="top__NavContent" style={{justifyContent:"flex-end"}}>
                        <div className="NavContent__right">
                            <div className="iconTray">
                                {role !== "Vendor" && (
                                    <button onClick={() => role === 'Staff' ? navigate(UrlTypes.STAFF_MESSAGE_LIST) : navigate(UrlTypes.ADMIN_MESSAGE_LIST)}>
                                        <span>
                                            <MessageIconSvg />
                                        </span>
                                        <span className="indicator"></span>
                                    </button>
                                )}
                                <button>
                                    <span>
                                        <NotificationSvg />
                                    </span>
                                    <span className="indicator"></span>
                                </button>
                            </div>
                            <div className="userBlock" onMouseOver={() => setOpenDrop(true)} onTouchStart={() => setOpenDrop(true)} onMouseOut={() => setOpenDrop(false)} onTouchCancel={() => setOpenDrop(false)}>
                                <div className="userProfileImg">

                                </div>
                                {account && account.isAuthenticated && (
                                    <div className="userProfileName">
                                        <span>{account.fullname.toString().slice(0, 13)}</span>
                                        <span>{account.role === "Staff" ? "Merchandiser" : account.role}</span>
                                    </div>
                                )}
                                
                                <div className="userBlockChev" >
                                    <span><IoChevronDown size={18} color={"#000"} /></span>
                                </div>

                                <div className={`dropDown ${openDrop ? "active" : ""}`}>
                                    <div className="userInfo userBlock">
                                        <div className="userProfileImg">

                                        </div>
                                        {account && account.isAuthenticated && (
                                            <div className="userProfileName">
                                                <span>{account.fullname.toString().slice(0, 13)}</span>
                                                <span>{account.role}</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="divider"></div>
                                    <ul>
                                        <li>
                                            <a href={role === "Admin" ? UrlTypes.ADMIN_PROFILE : role === "Vendor" ? UrlTypes.VENDOR_PROFILE : UrlTypes.STAFF_PROFILE} className={`${location === "dashboard" ? "active" : ""}`}>
                                                <span><ProfileUserSvg /></span>
                                                <span>My Profile</span>
                                            </a>
                                        </li>

                                        {role !== "Vendor" && (
                                            <li className="showMobile">
                                                <a href={role === 'Staff' ? UrlTypes.STAFF_MESSAGE_LIST : UrlTypes.ADMIN_MESSAGE_LIST} className={`${location === "dashboard" ? "active" : ""}`}>
                                                    <span><MessageIconSvg /></span>
                                                    <span>Message</span>
                                                </a>
                                            </li>
                                        )}
                                        
                                        <li className="showMobile">
                                            <a href={role === "Admin" ? UrlTypes.ADMIN_PROFILE : role === "Vendor" ? UrlTypes.VENDOR_PROFILE : UrlTypes.STAFF_PROFILE} className={`${location === "dashboard" ? "active" : ""}`}>
                                                <span><NotificationSvg /></span>
                                                <span>Notifications</span>
                                            </a>
                                        </li>
                                        {/* <li>
                                            <a href="#0" className={`${location === "dashboard" ? "active" : ""}`}>
                                                <span><SettingSvg color="#808280" /></span>
                                                <span>Settings</span>
                                            </a>
                                        </li> */}
                                    </ul>
                                    <div className="divider"></div>
                                    <ul>
                                        <li>
                                            <a href="#logout" onClick={callLogout} className={`${location === "dashboard" ? "active" : ""}`}>
                                                <span><LogoutSvg color="#EB212F" /></span>
                                                <span style={{color:"#EB212F"}}>Log Out</span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>  

            <div className={`side__Navbar ${expandMenu ? "active" : ""}`}>
                <div>
                    <ul>
                        <li>
                            <a href={role === 'Admin' ? `${UrlTypes.ADMIN_DASHBOARD}` : role === 'Vendor' ? `${UrlTypes.VENDOR_DASHBOARD}` : `${UrlTypes.STAFF_DASHBOARD}`} className={`${location === "dashboard" ? "active" : ""}`}>
                                <span><DashboardSvg /></span>
                                <span>Dashboard</span>
                            </a>
                        </li>
                        {role === 'Admin' && (
                            <>
                                <li>
                                    <a href={`${UrlTypes.ADMIN_PRODUCT_LIST}`} className={`${location === "product" ? "active" : ""}`}>
                                        <span><ProductSvg /></span>
                                        <span>Products</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={UrlTypes.ADMIN_PRODUCT_ADD} className={`${location === "create_product" ? "active" : ""}`}>
                                        <span><AddProductSvg /></span>
                                        <span>Create Products</span>
                                    </a>
                                </li>
                                {/* <li>
                                    <a href={UrlTypes.ADMIN_PRODUCT_IMPORT} className={`${location === "import_product" ? "active" : ""}`}>
                                        <span><ImportProductSvg /></span>
                                        <span>Import Product</span>
                                    </a>
                                </li> */}
                            </>
                        )}

                        {role === 'Staff' && (
                            <>          
                                <li>
                                    <a href={UrlTypes.STAFF_STORE_LIST} className={`${location === "store_list" ? "active" : ""}`}>
                                        <span><StoreSvg /></span>
                                        <span>Store Manager</span>
                                    </a>
                                </li>
                            </>
                        )}
                        
                        {role === 'Admin' && (
                            <>
                                <li>
                                    <a href={UrlTypes.ADMIN_PRODUCT_EXPIRING} className={`${location === "expiring_product" ? "active" : ""}`}>
                                        <span><ExpiredProductSvg /></span>
                                        <span>Expiry</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={UrlTypes.ADMIN_PRODUCT_DAMAGED} className={`${location === "damaged_product" ? "active" : ""}`}>
                                        <span><DamagesSvg /></span>
                                        <span>Damages & Returns</span>
                                    </a>
                                </li>
                            </>
                        )}

                        {role === "Vendor" && (
                            <li>
                                <a href={UrlTypes.VENDOR_STORE_LIST} className={`${location === "vendor_store_list" ? "active" : ""}`}>
                                    <span><StoreSvg /></span>
                                    <span>Store</span>
                                </a>
                            </li>  
                        )}
                    </ul>
                    <div className="divider"></div>
                    <ul>
                        {role === "Admin" && (
                            <>
                                <li>
                                    <a href={UrlTypes.ADMIN_STORE_LIST} className={`${location === "store" ? "active" : ""}`}>
                                        <span><StoreSvg /></span>
                                        <span>Store</span>
                                    </a>
                                </li>
                                <li>
                                    <a href={UrlTypes.ADMIN_STAFF_LIST} className={`${location === "staff" ? "active" : ""}`}>
                                        <span><StaffSvg /></span>
                                        <span>Staff</span>
                                    </a>
                                </li>  
                                <li>
                                    <a href={UrlTypes.ADMIN_VENDOR_LIST} className={`${location === "vendor" ? "active" : ""}`}>
                                        <span><VendorSvg /></span>
                                        <span>Vendor</span>
                                    </a>
                                </li>
                            </>
                        )}
                        
                        {role !== 'Vendor' && (
                            <>
                                <li>
                                    <a href={role === 'Staff' ? UrlTypes.STAFF_MESSAGE_LIST : UrlTypes.ADMIN_MESSAGE_LIST} className={`${location === "message" ? "active" : ""}`}>
                                        <span><MessageSvg /></span>
                                        <span>Message</span>
                                    </a>
                                </li>  
                            </>
                        )}
                        <li>
                            <a href={role === 'Staff' ? UrlTypes.STAFF_TICKET_LIST : role === 'Vendor' ? UrlTypes.VENDOR_TICKET_LIST : UrlTypes.ADMIN_TICKET_LIST} className={`${location === "ticket" ? "active" : ""}`}>
                                <span><TicketSvg /></span>
                                <span>Ticket</span>
                            </a>
                        </li>
                    </ul>
                    <div className="divider"></div>
                    <ul>
                        {/* <li>
                            <a href="#0" className={`${location === "setting" ? "active" : ""}`}>
                                <span><SettingSvg /></span>
                                <span>Setting</span>
                            </a>
                        </li> */}
                        <li>
                            <a href="#logout" onClick={callLogout} className={`${location === "logout" ? "active" : ""}`}>
                                <span><LogoutSvg /></span>
                                <span>Log Out</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Navigation;