import { generalObj } from "../../constant/object_types"

const ChartTimeFrameSelector = ({ timeFrame, onUpdateFrame }:{
    timeFrame : generalObj[],
    onUpdateFrame: (id:number) => void
}) => {

    return (
        <div className="chart__timeFrame">
            {timeFrame.map((item) => (
                <button key={item.id} className={`${item.active ? "active" : ""}`} onClick={() => onUpdateFrame(item.id)}>{item.name}</button>
            ))}
        </div>
    )
}

export default ChartTimeFrameSelector;