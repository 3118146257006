import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { UrlTypes } from "../../constant/object_types";

const AddDropDown = () => {
    const [openDrop, setOpenDrop] = useState<boolean>(false);
    return (
        <>
            <div className="dropSpread" onClick={() => setOpenDrop(false)}></div>
            <div className="addedDropdown">
                <button className={`${openDrop ? "active" : ""}`} onClick={() => setOpenDrop(!openDrop)}>
                    <span><FiChevronDown size={24} color={"#676967"} /></span>
                </button>
                <div className={`${openDrop ? "active" : ""}`}>
                    <ul>
                        <li><a href={`${UrlTypes.ADMIN_PRODUCT_ADD}`}>Add Product</a></li>
                        <li><a href={`${UrlTypes.ADMIN_PRODUCT_LIST}`}>Product List</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default AddDropDown;