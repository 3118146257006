import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { dateStr } from "../../action/generalFunc";
import Table from "../Table";

const QuickNotif = ({ visible, productList, onSend }:{
    visible: boolean,
    productList: generalObj[],
    onSend : () => void
}) => {
    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop expiry__pop" style={{width:"60%",backgroundColor:"#CBCFCB"}}>
                    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', gap:'10px'}}>
                        <div>
                            <h2 style={{textAlign:"center"}}>{productList.length} Product items are about to expire</h2>
                        </div>
                        <div style={{display:"flex", justifyContent:'center', alignItems:'center'}}>
                            <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#CA0412", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => onSend()}/>
                        </div>
                    </div>
                    <div style={{marginTop:"12px"}}>
                        <Table table_head={["ID", "Product name", "Size", "Quantity", "Expiry Date"]} th_class="white" color={"#1C1C1C"} thColor={"#1C1C1C"} 
                        headerStyle={{marginBottom: "44px"}}
                        styleClass="expireTable"
                        >
                            <>
                                {productList.map((item, index) => (
                                    (item.show || item.show === undefined) && (
                                        <tr key={index}>
                                            <td>#{item.expiry_id}</td>
                                            <td>
                                                <div className="contentWithImg">
                                                    <span><img src={item.image} alt={item.name} /></span>
                                                    <span>{item.name}</span>
                                                </div>
                                            </td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>{dateStr(item.expiry_date)}</td>
                                        </tr>
                                    )
                                ))}
                            </>
                        </Table>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}

export default QuickNotif;