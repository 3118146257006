import { useState } from "react";
import { FiChevronDown } from "react-icons/fi";

const TebleHeadElement = ({ title, current_value }:{
    title:string,
    current_value?:string|null
}) => {
    const [openDrop, setOpenDrop] = useState<boolean>(false);
    const getLocationString = (val:string) => {
        let url = window.location.pathname;

        return `${url}?range=${val}`;
    }
    return (
        <>

            {openDrop && <div className="dropSpread" onClick={() => setOpenDrop(false)}></div>}
            <div className="headerElemenDrop">
                <button className={`${openDrop ? "active" : ""}`} onClick={() => setOpenDrop(!openDrop)}>
                    <span>{title}</span>
                    <span><FiChevronDown size={18} color={"#FFF"} /></span>
                </button>
                <div className={`${openDrop ? "active" : ""}`}>
                    <ul>
                        <li><a className={`${current_value === 'weekly' ? "active" : ""}`} href={getLocationString('weekly')}>Weekly</a></li>
                        <li style={{marginTop:"10px"}}><a className={`${current_value === 'monthly' ? "active" : ""}`} href={getLocationString('monthly')}>Monthly</a></li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default TebleHeadElement;