import { IoAdd } from "react-icons/io5";
import { CardSearch, ConfirmationModal, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { DeleteButtonSvg, EditButtonSvg } from "../../style/iconStyle";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, deleteStoreListById, fetchStoreList, fetchStoreListReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const StoreList = () => {
    const navigate = useNavigate();
    const [storeList, setStoreList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [itemToDelete, setItemToDelete] = useState<generalObj | null>(null);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let result = await fetchStoreList(account.token);
            if (result) {
                setStoreList(result.data.store);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading]);

    const selectItemForDelete = (id:number|string) => {
        let item_obj = storeList.filter((item) => item.id === id);

        if (item_obj.length === 0) return;
        setItemToDelete(item_obj[0]);
        setOpenConfirmation(true);
    }

    const onDelete = async () => {
        if (!account || !itemToDelete) return;
        setModalLoading(true);
        let result = await deleteStoreListById(account.token, itemToDelete.id);
        if (result) {
            toast(result.message);
            setStoreList((prevStoreList) => {
                return (
                    prevStoreList.filter((item) => item.id !== itemToDelete.id)
                )
            });
        }
        setItemToDelete(null);
        setOpenConfirmation(false)
        setModalLoading(false)
    }

    const onSearch = (val:string) => {
        setStoreList((prevStoreList) => {
            return (
                prevStoreList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || item.organization.toLowerCase().includes(val.toLowerCase()) || val.includes(item.store_staff.length) || val.includes(dateStr(item.date_of_creation))) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        generateReport(export_type);
    }

    const generateReport = async (export_type:string) => {
        if (!export_type || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let param = {
            type: export_type,
        };
        
        let result = await fetchStoreListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `store_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Store List" desc="Manage stores">
                <button className="product_create_button" onClick={() => navigate(UrlTypes.ADMIN_STORE_ADD)}>
                    <span><IoAdd /></span>
                    <span>Create Store</span>
                </button>
            </PageBodyHeader>

            <Table table_head={["Store name", "Total Staff", "Organization", "Created at", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {storeList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>{item.name}</td>
                                <td>{item.store_staff.length}</td>
                                <td>{item.organization}</td>
                                <td>{dateStr(item.date_of_creation)}</td>
                                <td>
                                    <div className="edit__delete_action">
                                        <button onClick={() => navigate(`${UrlTypes.ADMIN_STORE_EDIT}${item.id}/`)}>
                                            <span>
                                                <EditButtonSvg />
                                            </span>
                                        </button>

                                        <button onClick={() => selectItemForDelete(item.id)}>
                                            <span>
                                                <DeleteButtonSvg />
                                            </span>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            {itemToDelete && <ConfirmationModal visible={openConfirmation} title={"Delete Confirmation"} description={`Do you want to delete ${itemToDelete.name}?`} closeText="No" submitText="Yes" modalClose={() => {setItemToDelete(null); setOpenConfirmation(false)}} onSubmit={() => onDelete()} />}
            
        </>
    )
}

export default StoreList;