import { ActionDropDown, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, fetchExpiryParentProduct, fetchProductExpListParentReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const VendorProductExpiry = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productNextList, setProductNextList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [searchNextText, setSearchNextText] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            setModalLoading(true);
            let result = await checkStoreByStoreIdUserId(account.token, id, account.id);
            if (!result) {
                window.history.back();
            }
            (async () => {
                let expiry_result = await fetchExpiryParentProduct(account.token, id)
                if (!expiry_result) {
                    window.history.back();
                }
                setProductList(expiry_result.products);
                setProductNextList(expiry_result.products_next);
                setStoreName(`${expiry_result.store_data.name} - ${expiry_result.store_data.organization}`)
                setModalLoading(false)
            })();
            
        })();
    }, [account, setModalLoading, id]);

    const processExport = (export_type:string, expiry:string) => {
        generateReport(export_type, expiry);
    }

    const onSearch = (val:string, expiring:boolean=true) => {
        if (expiring) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductNextList((prevProductNextList) => {
                return (
                    prevProductNextList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || val.toLowerCase().includes(item.quantity) || item.expiry_count.toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchNextText(val);
        }
        
    }

    const generateReport = async (exportType:string, expiry:string) => {
        if (!exportType || !account || !account.isAuthenticated || !id) return;

        setModalLoading(true);
        let param = {
            type: exportType,
            expire_type:expiry,
            store: id
        };
        
        let result = await fetchProductExpListParentReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_expiry_vendor.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Expired Products" desc="Checklist of Expired products" >
                <button className="product_create_button" style={{color:"#FFF", backgroundColor:"#676967", borderColor:"#676967"}} onClick={() => window.history.back()}>
                    <span>Back</span>
                </button>
            </PageBodyHeader>

            <div>
                <Table table_head={["S/no", "Product name", "Size", "Quantity", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
                tableAction={<ExportData export_type={(val) => processExport(val, "under")} />}
                headerStyle={{marginBottom: "44px"}}
                table_title={`About to expire products for ${storeName}`}
                >
                    <>
                        {productList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{index + 1}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_EXPIRY_SUB}${id}/${item.id}?expiring=true`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>

            <div style={{marginTop:"32px"}}>
                <Table table_head={["S/no", "Product name", "Size", "Quantity", "Expiry Count", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchNextText} onTextChange={(evt) => onSearch(evt, false)} />}
                tableAction={<ExportData export_type={(val) => processExport(val, "over")} />}
                headerStyle={{marginBottom: "44px"}}
                table_title={`Products and their expiry for ${storeName}`}
                >
                    <>
                        {productNextList.map((item, index) => (
                            (item.show || item.show === undefined) && (
                                <tr key={index}>
                                    <td>#{index+1}</td>
                                    <td>
                                        <div className="contentWithImg">
                                            <span><img src={item.image} alt={item.name} /></span>
                                            <span>{item.name}</span>
                                        </div>
                                    </td>
                                    <td>{item.size}</td>
                                    <td>{item.quantity}</td>
                                    <td>{item.expiry_count}</td>
                                    <td>
                                        <div className="edit__delete_action">
                                            <ActionDropDown>
                                                <ul>
                                                    <li>
                                                        <button onClick={() => navigate(`${UrlTypes.VENDOR_PRODUCT_EXPIRY_SUB}${id}/${item.id}`)}>View</button>
                                                    </li>
                                                </ul>
                                            </ActionDropDown>
                                        </div>
                                    </td>
                                </tr>
                            )
                        ))}
                    </>
                </Table>
            </div>
        </>
    )
}

export default VendorProductExpiry;