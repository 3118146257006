import { useEffect, useState } from "react";
import { ButtonFrame, Card, CardSearch, InputField, PageBodyHeader, Table, UploadField } from "../../component"
import { generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { checkStoreByStoreIdUserId, fetchStockOrder, updateStockOrder, uploadStockOrder } from "../../action/generalFunc";
import { useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const StockOrder = () => {
    const [formData, setFormData] = useState<generalObj>({
        order_image:null,
        order_name: ""
    });
    const [stockList, setStockList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [storeName, setStoreName] = useState<string>("");
    const [vendorName, setVendorName] = useState<string>("");
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext()

    const account = useSelector((state:RootState) => state.account);

    const { id, store_id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        (async () => {
            let result = await checkStoreByStoreIdUserId(account.token, store_id, id);
            if (!result) {
                window.history.back();
            }else {
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
                setVendorName(`${result.data.vendor_data.username}`)
            }
            (async () => {
                let stock_result =  await fetchStockOrder(account.token, store_id);
                if (!stock_result) {
                    window.history.back();
                }
                setStockList(stock_result.stock);
                setModalLoading(false);
            })();
        })();
    }, [account, id, setModalLoading, store_id]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const pickImage = (e:generalObj, isDrag:boolean=false) => {
        if ((!isDrag && !e.target.files.length)) return;
        const reader = new FileReader();
        const file = !isDrag ? e.target.files[0] : e;
        if (file.type !== "image/png" && file.type !== "image/jpg" && file.type !== "image/jpeg") {
            toast("Only image files are allowed");
            return;
        }
        if (Math.floor(file.size / 1000) > 1024) {
            toast("Image size is greater than 1mb");
            return;
        }
        reader.readAsArrayBuffer(file);
        reader.onload = () => {
            if (!reader || !reader.result) return;

            const blob = file.type === "application/pdf" ? new Blob([reader.result], {type:"application/pdf"}) : new Blob([reader.result], {type:"image/png"});
            if (file.type === "application/pdf") {
                setFormData({...formData, order_image:blob, image:require("../../assets/img/pdf_icon.png")});
            }else {
                updateFormData("order_image", blob);
            }
            
        }
    }

    const uploadPurchaseOrder = async () => {
        if (!account || !account.isAuthenticated || !id || !store_id) return;
        if (!formData.order_image) {
            toast("Purchase Order file is required for upload");
            return;
        }
        if (formData.order_name.length <= 0) {
            toast("Upload name required");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            stock_file: formData.order_image,
            vendor: id,
            store: store_id,
            file_name:formData.order_name
        };

        let result = await uploadStockOrder(account.token, api_data);
        if (result) {
            toast(result);
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        }
        setModalLoading(false);
    }

    const fetchUpdateStock = async (id:number) => {
        if (!account || !account.isAuthenticated) return;
        setModalLoading(true);
        let api_data:generalObj = {
            stock_id: id
        };

        let result = await updateStockOrder(account.token, api_data);
        if (result) {
            setTimeout(() => {
                window.location.reload();
            }, 2000)
        } 
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Upload Orders" desc="Upload Purchase Orders">
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
            </PageBodyHeader>

            <h3 style={{marginBottom:"18px", textAlign:"center"}}>{`Upload Stock Order for ${vendorName.toLocaleUpperCase()} in ${storeName} store`}</h3>

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div>
                        <UploadField label={"Purchase order (png, jpeg) *"} current_obj={formData.order_image} current_obj_url={formData.image ? formData.image : ""} upload_description="Drag and Drop a file to Upload (Max. 1mb)" file_types={"image/png, image/jpg, image/jpeg"} containerStyle={{width:"100%"}} inputContainerStyle={{height:"150px"}} onSelectFile={(val, isDrag) => pickImage(val, isDrag)} onInputClear={() => {updateFormData("order_image", null); updateFormData("image", "")}} />
                    </div>

                    <div style={{marginTop:"44px"}}>
                        <InputField label={"Upload Name *"} inputProp={{value:formData.order_name}} onTextChange={(evt) => updateFormData("order_name", evt)} containerStyle={{width:"100%"}}  />
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Upload" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={uploadPurchaseOrder} />
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>

                </>
            </Card>

            <div style={{marginTop:"30px"}}>
                <Table table_head={["S/no", "Stock Name", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                tableSearch={<CardSearch value={searchText} onTextChange={(evt) => setSearchText(evt)} />}
                headerStyle={{marginBottom: "44px"}}
                >
                    <>
                        {stockList.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    {index + 1}
                                </td>
                                <td>{item.name}</td>
                                <td>
                                    <div className="edit__delete_action">
                                        <button onClick={() => fetchUpdateStock(item.id)} className={`status__container completed`} style={{width:"100px", padding:"5px", height:"auto"}}>
                                            Mark as Filled
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </>
                </Table>
            </div>
        </>
    )
}

export default StockOrder;