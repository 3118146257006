import { useState } from "react";
import InputField from "../InputField";
import ModalParent from "./ModalParent";
import { generalObj } from "../../constant/object_types";
import ButtonFrame from "../ButtonFrame";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { useOutletContext, useParams } from "react-router-dom";
import { createReturns } from "../../action/generalFunc";
import { toast } from "react-toastify";

const AddReturnModal = ({ visible, modalClose, onAdded }:{
    visible: boolean,
    modalClose: () => void,
    onAdded : () => void
}) => {

    const { order_id } = useParams();
    const [formData, setFormData] = useState<generalObj>({
        returns: "",
        return_date: ""
    });

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext(); 

    const addStore = async () => {
        if (!account || !account.isAuthenticated || !order_id) return;
        if (formData.returns.length === 0) {
            toast("Return quantity is required");
            return;
        }
        if (parseInt(formData.returns) < 0) {
            toast("Return quantity is not a number");
            return;
        }
        if (formData.return_date === "") {
            toast("Return quantity date is required");
            return;
        }

        setModalLoading(true);
        let api_data:generalObj = {
            parent_id: order_id,
            return_qty: formData.returns,
            return_date: formData.return_date
        };

        let result = await createReturns(account.token, api_data);
        if (result) {
            toast(result);
            setTimeout(() => {
                onAdded();
            }, 2000);
        }
        setModalLoading(false);
    }

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    return (
        <ModalParent visible={visible}>
            <div className="ViewContainer">
                <div className="input__pop">
                    <h4>Add Returned Items</h4>
                    <div>
                        <InputField label={"Returns"} inputProp={{value:formData.returns, placeholder:"Enter number returned products", type:"number", step:"1"}} onTextChange={(evt) => updateFormData("returns", evt)} containerStyle={{width:"100%"}} />
                        <InputField label={`Return Date`} inputProp={{value:formData.return_date, type:"date", pattern:"d{4}-d{2}-d{2}"}} onTextChange={(evt) => updateFormData("return_date", evt)} containerStyle={{width:"100%", marginTop:"24px"}} />
                                        
                    </div>

                    <div style={{display:"flex", justifyContent:'space-between', alignItems:'center', marginTop:"60px"}}>
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => modalClose()} />
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addStore()}/>
                    </div>
                </div>
            </div>
        </ModalParent>
    )
}
export default AddReturnModal;