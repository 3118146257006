import { useEffect, useState } from "react";
import { ButtonFrame, Card, InputDropdown, InputField, PageBodyHeader} from "../../component"
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { addUser, fetchStoreList, fetchUserListById } from "../../action/generalFunc";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FiEye, FiEyeOff } from "react-icons/fi";

const AddStaff = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        firstname: "",
        lastname: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        confirm_password: "",
        store: null,
        secure_entry: true
    });

    const [storeList, setStoreList] = useState<generalObj[]>([]);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();

    const account = useSelector((state:RootState) => state.account);
    const { id } = useParams();

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        if (id) {
            (async () => {
                let result = await fetchUserListById(account.token, id);
                if (result) {
                    let store_data:generalObj[] = [];
                    result.data.user.store.forEach((element:generalObj) => {
                        store_data.push({
                            id: element.id,
                            label: `${element.name} - ${element.organization}`,
                            value: element.name
                        })
                    });
                    setFormData({...result.data.user, store: store_data.length > 0 ? store_data : null,password: "", confirm_password: "",});
                }else {
                    navigate(UrlTypes.ADMIN_STAFF_LIST);
                }
            })();
        }
        (async () => {
            let result = await fetchStoreList(account.token);
            if (result) {
                let str_list:generalObj[] = [];
                result.data.store.forEach((element:generalObj) => {
                    str_list.push({
                        id: element.id,
                        label: `${element.name} - ${element.organization}`,
                        value: element.name
                    });
                });
                setStoreList(str_list);
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading, id, navigate]);

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const updateStoreList = (val:generalObj) => {
        let store_list:generalObj[];
        if (formData.store) {
            let isexists = formData.store.filter((item:generalObj) => item.id === val.id);
            store_list = isexists.length > 0 ? formData.store.filter((item:generalObj) => item.id !== val.id) : [...formData.store, val];
        }else {
            store_list = [val];
        }

        setFormData((prevFormData) => ({...prevFormData, store:store_list}));
    }

    const addStaffUser = async () => {
        if (!account) return;
        if (formData.firstname.length === 0 || formData.lastname.length === 0 || formData.username.length === 0 || formData.mobile.length === 0 || formData.email.length === 0 || (!id && (formData.password.length === 0 || formData.confirm_password.length === 0)) || !formData.store) {
            toast("All fields are required");
            return;
        }
        if (formData.password !== formData.confirm_password) {
            toast("passwords doesn't match");
            return;
        }
        setModalLoading(true);
        let api_data:generalObj = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            username: formData.username,
            mobile: formData.mobile,
            email: formData.email,
            password: formData.password,
            role: "Staff"
        };

        let staff_store:Array<number> = [];
        formData.store.forEach((element:generalObj) => {
            staff_store.push(element.id)
        });
        api_data.store = staff_store;

        let method = id ? "put" : "post";
        if (id) {
            api_data.id = id
        }

        let result = await addUser(account.token, api_data, method);
        if (result) {
            toast(result.message)
            navigate(UrlTypes.ADMIN_STAFF_LIST);
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Add Staff" desc="Add/Update Staff" />

            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div className="mb-wrap" style={{display:'flex', gap:"24px", alignItems:"center"}}>
                        <InputField label={"Firstname"} inputProp={{value:formData.firstname}} onTextChange={(evt) => updateFormData("firstname", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                        <InputField label={"Lastname"} inputProp={{value:formData.lastname}} onTextChange={(evt) => updateFormData("lastname", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                    </div>
                    <div className="mb-wrap" style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"20px"}}>
                        <InputField label={"Username"} inputProp={{value:formData.username}} onTextChange={(evt) => updateFormData("username", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                        <InputField label={"Mobile"} inputProp={{value:formData.mobile}} onTextChange={(evt) => updateFormData("mobile", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                    </div>

                    <div className="mb-wrap" style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"20px"}}>
                        <InputField label={"Email"} inputProp={{value:formData.email, type:"email"}} onTextChange={(evt) => updateFormData("email", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}} />
                        <InputDropdown label={"Store"} data={storeList} placeHolder="Select all that apply" selectedMulData={formData.store} mulitple={true} containerClass={"mb-w-100"} containerStyle={{width:"50%"}} onItemChange={(val) => updateStoreList(val)} />
                    </div>

                    <div className="mb-wrap" style={{display:'flex', gap:"24px", alignItems:"center", marginTop:"20px"}}>
                        <InputField label={"Password"} inputProp={{value:formData.password, type:`${formData.secure_entry ? "password" : "text"}`}} onTextChange={(evt) => updateFormData("password", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}} isIconRight={formData.secure_entry ? <FiEyeOff size={16} color="#999C99" /> : <FiEye size={16} color="#999C99" />} iconPress={() => updateFormData("secure_entry", !formData.secure_entry)}  />
                        <InputField label={"Confirm Password"} inputProp={{value:formData.confirm_password, type:`${formData.secure_entry ? "password" : "text"}`}} onTextChange={(evt) => updateFormData("confirm_password", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}} isIconRight={formData.secure_entry ? <FiEyeOff size={16} color="#999C99" /> : <FiEye size={16} color="#999C99" />} iconPress={() => updateFormData("secure_entry", !formData.secure_entry)}  />
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => addStaffUser()} />
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>

                </>
            </Card>
        </>
    )
}

export default AddStaff;