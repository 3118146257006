import { useEffect, useState } from "react";
import { ButtonFrame, Card, InputField, PageBodyHeader } from "../component"
import { generalObj } from "../constant/object_types";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { useSelector } from "react-redux";
import { RootState, actionCreator } from "../redux";
import { useOutletContext } from "react-router-dom";
import { fetchUserListById } from "../action/generalFunc";
import { toast } from "react-toastify";
import { EditProfileSvg } from "../style/iconStyle";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const Profile = () => {
    
    const [formData, setFormData] = useState<generalObj>({
        firstname: "",
        lastname: "",
        username: "",
        mobile: "",
        email: "",
        password: "",
        secure_entry: true
    });

    const dispatch = useDispatch();
    const { updateUser } = bindActionCreators(actionCreator, dispatch);

    const account = useSelector((state:RootState) => state.account);
    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            setModalLoading(true);
            let result = await fetchUserListById(account.token, account.id);
            if (result) {
                setFormData({...result.data.user, password: "", secure_entry: true});
            }else {
                window.history.back();
            }
            setModalLoading(false);
        })();
    }, [account, setModalLoading])

    const updateFormData = (key:string, value:any) => {
        setFormData({...formData, [key]:value});
    }

    const updateUserProfile = async () => {
        if (!account || !account.isAuthenticated) return;
        if (formData.firstname.length === 0 || formData.lastname.length === 0 || formData.username.length === 0 || (formData.mobile && formData.mobile.length === 0) || formData.email.length === 0) {
            toast("Asterisk fields are required");
            return;
        }
        
        setModalLoading(true);
        let api_data:generalObj = {
            firstname: formData.firstname,
            lastname: formData.lastname,
            username: formData.username,
            mobile: formData.mobile,
            email: formData.email,
            password: formData.password,
            role: "Staff"
        };

        if (formData.password.length >= 5) {
            api_data.password = formData.password;
        }
        
        let result:generalObj | boolean = await updateUser(account.token, api_data);
        if (result) {
            toast(result.message)
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Profile" desc="User Profile" />
            <Card color="#676967" containerCardStyle={{overflow:"auto"}}>
                <>
                    <div className="profileHeader" style={{position:"relative",paddingTop:"65px", paddingInline: "10px", marginBottom:"24px"}}>
                        <div style={{position:"absolute",width: "100%",borderRadius: "5px 5px 0px 0px",height: "100px", backgroundColor:"#00983A", top: "0px",left: "0px"}}></div>
                        <div style={{position:"relative"}}>
                            <div className="userInfo userBlock" style={{display:"flex", alignItems:"flex-end", gap:"15px"}}>
                                <div className="userProfileImg" style={{width:"80px", height:"80px", borderRadius:"50%"}}>
                                </div>
                                <div style={{display:"flex", width:"calc(100% - 150px)", justifyContent:"space-between", paddingRight:"50px"}}>
                                    {account && account.isAuthenticated && (
                                        <div className="userProfileName">
                                            <span>{account.fullname.toString().slice(0, 13)}</span>
                                            <span>{account.role}</span>
                                        </div>
                                    )}
                                    <div style={{display: "flex", alignItems: "center",gap: "5px"}}>
                                        <span style={{display: "inline-block",width: "20px",height: "20px"}}><EditProfileSvg /></span>
                                        <span style={{fontSize:"14px"}}>Edit Profile</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mb-wrap" style={{display:'flex', gap:"14px", alignItems:"center"}}>
                        <InputField label={"First name"} inputProp={{value:formData.firstname}} onTextChange={(evt) => updateFormData("firstname", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                        <InputField label={"Last name"} inputProp={{value:formData.lastname}} onTextChange={(evt) => updateFormData("lastname", evt)} containerClass={"mb-w-100 mb-mt-14"} containerStyle={{width:"50%"}}  />
                    </div>

                    <div className="mb-wrap" style={{display:'flex', gap:"14px", alignItems:"center", marginTop:"20px"}}>
                        <InputField label={"Email"} inputProp={{value:formData.email, type:"email"}} onTextChange={(evt) => updateFormData("email", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}} />
                        <InputField label={"Mobile"} inputProp={{value:formData.mobile}} onTextChange={(evt) => updateFormData("mobile", evt)} containerClass={"mb-w-100 mb-mt-14"} containerStyle={{width:"50%"}}  />
                    </div>

                    <div className="mb-wrap" style={{display:'flex', gap:"14px", alignItems:"center", marginTop:"20px"}}>
                        <InputField label={"Username"} inputProp={{value:formData.username}} onTextChange={(evt) => updateFormData("username", evt)} containerClass={"mb-w-100"} containerStyle={{width:"50%"}}  />
                        <InputField label={"Password"} inputProp={{value:formData.password, type:`${formData.secure_entry ? "password" : "text"}`}} containerClass={"mb-w-100 mb-mt-14"} onTextChange={(evt) => updateFormData("password", evt)} containerStyle={{width:"50%"}} isIconRight={formData.secure_entry ? <FiEyeOff size={16} color="#999C99" /> : <FiEye size={16} color="#999C99" />} iconPress={() => updateFormData("secure_entry", !formData.secure_entry)}  />
                    </div>

                    <div style={{marginTop:"44px", display:"flex", alignItems:'center', gap:"20px"}}>
                        <ButtonFrame btn_text="Submit" btn_style={{padding:"12px 24px", borderRadius:"8px", backgroundColor:"#00983A", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => updateUserProfile()} />
                        <ButtonFrame btn_text="Cancel" btn_style={{padding:"12px 24px", borderRadius:"8px", border:"1px solid #808280", color:"#808280", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />
                    </div>

                </>
            </Card>
        </>
    )
}

export default Profile;