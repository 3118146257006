import { useState } from "react";
import { IoEllipsisVertical } from "react-icons/io5";

const ActionDropDown = ({ children }:{
    children : JSX.Element
}) => {
    const [openDrop, setOpenDrop] = useState<boolean>(false);
    return (
        <>
            {openDrop && <div className="dropSpread" onClick={() => setOpenDrop(false)}></div>}
            <div className="addedDropdown">
                <button className={`${openDrop ? "active" : ""}`} onClick={() => setOpenDrop(!openDrop)}>
                    <span><IoEllipsisVertical size={"18px"} color="#9E9B9B" style={{margin:"0px 5px", top:"7px"}} /></span>
                </button>
                <div className={`${openDrop ? "active" : ""}`}>
                    {children}
                </div>
            </div>
        </>
    )
}

export default ActionDropDown;