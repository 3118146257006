import { ButtonFrame, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, encryptEmail, encryptPhone, fetchUserListByStoreId, fetchUserListReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const StaffVendorList = () => {
    const navigate = useNavigate();
    const [vendorList, setVendorList] = useState<generalObj[]>([]);
    const [storeName, setStoreName] = useState<string>("");
    const [searchText, setSearchText] = useState<string>("");

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { id } =useParams();
     
    useEffect(() => {
        if (!account || !account.isAuthenticated || !id) return;
        (async () => {
            let result = await fetchUserListByStoreId(account.token, id, "vendor");
            if (result) {
                setVendorList(result.data.users);
                setStoreName(`${result.data.store_data.name} - ${result.data.store_data.organization}`)
            }else{
                window.history.back();
            }
            setModalLoading(false);
        })();
    }, [account, id, setModalLoading]);

    const onSearch = (val:string) => {
        setVendorList((prevVendorList) => {
            return (
                prevVendorList.filter((item) => {
                    if (item.username.includes(val) || item.mobile.includes(val) || item.email.includes(val) || (item.store && val.includes(`${item.store.length}`))) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const processExport = (export_type:string) => {
        generateReport(export_type)
    }

    const generateReport = async (export_type:string) => {
        if (!export_type || !account || !account.isAuthenticated || !id) return;

        setModalLoading(true);
        let param = {
            type: export_type,
            user_type: "vendor",
            store: id
        };
        
        let result = await fetchUserListReport(account.token, param);
        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `vendor_list.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Vendor List" desc="Manage vendors">
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => navigate(`${UrlTypes.STAFF_STORE_LIST}`)} />
            </PageBodyHeader>

            <Table table_head={["Username", "Phone", "Email", "Created on", "Status"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            table_title={`Vendors for ${storeName}`}
            >
                <>
                    {vendorList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>
                                    {item.username}
                                </td>
                                <td>{encryptPhone(item.mobile)}</td>
                                <td>{encryptEmail(item.email)}</td>
                                <td>{dateStr(item.created)}</td>
                                <td><button onClick={() => navigate(`${UrlTypes.STAFF_STORE_MENU}${item.id}/${id}/`)} className={`status__container completed`} style={{width:"60px", padding:"5px"}}>View</button></td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
        </>
    )
}

export default StaffVendorList;