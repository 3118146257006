import { ButtonFrame, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { generalObj } from "../../constant/object_types";
import { useOutletContext, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { dateStr, fetchExpiryProduct, fetchProductExpListReport } from "../../action/generalFunc";
import { toast } from "react-toastify";

const ProductExpirySubLevel = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [productNextList, setProductNextList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [searchNextText, setSearchNextText] = useState<string>("");
    const [isExpiring, setIsExpiring] = useState<boolean>(false);

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const { child_id } = useParams()
     
    useEffect(() => {
        if (!account || !account.isAuthenticated || !child_id) return;
        (async () => {
            setModalLoading(true);
            const queryParameters = new URLSearchParams(window.location.search)
            let expiry_result = await fetchExpiryProduct(account.token, undefined, undefined, child_id)
            if (!expiry_result) {
                window.history.back();
            }
            setIsExpiring(queryParameters.get('expiring') ?  true : false)
            setProductList(expiry_result.products);
            setProductNextList(expiry_result.products_next);
            setModalLoading(false);
        })();
    }, [account, child_id, setModalLoading]);

    const processExport = (export_type:string, expiry:string) => {
        generateReport(export_type, expiry)
    }

    const onSearch = (val:string, expiring:boolean=true) => {
        if (expiring) {
            setProductList((prevProductList) => {
                return (
                    prevProductList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || item.expiry_id.toString().toLowerCase().includes(val.toLowerCase())  || val.toLowerCase().includes(item.quantity) || dateStr(item.expiry_date).toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchText(val);
        }else {
            setProductNextList((prevProductNextList) => {
                return (
                    prevProductNextList.filter((item) => {
                        if (item.name.toLowerCase().includes(val.toLowerCase()) || item.expiry_id.toString().toLowerCase().includes(val.toLowerCase())  || val.toLowerCase().includes(item.quantity) || dateStr(item.expiry_date).toString().includes(val.toLowerCase())) {
                            item.show = true;
                        }else {
                            item.show = false;
                        }
                        return item;
                    })
                )
            })
            setSearchNextText(val);
        }
        
    }

    const generateReport = async (export_type:string, expiry:string) => {
        if (!export_type || !account || !account.isAuthenticated || !child_id) return;

        setModalLoading(true);
        let param = {
            type: export_type,
            expire_type:expiry,
            parent: child_id
        };
        
        let result = await fetchProductExpListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_expiry_admin.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="About to Expire Products" desc="View/Search about to expire products">
                <ButtonFrame btn_text="Back" btn_style={{padding:"8px 24px", borderRadius:"8px", border:"1px solid #808280",  backgroundColor:"#808280", color:"#FFF", fontSize:"14px", fontWeight:"600", letterSpacing:"-0.4px"}} onPress={() => window.history.back()} />    
            </PageBodyHeader>

            {isExpiring ? (
                <div>
                    <Table table_head={["ID", "Product name", "Size", "Quantity", "Store", "Expiry Date"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                        tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
                        tableAction={<ExportData export_type={(val) => processExport(val, 'under')} />}
                        headerStyle={{marginBottom: "44px"}}
                        >
                            <>
                                {productList.map((item, index) => (
                                    (item.show || item.show === undefined) && (
                                        <tr key={index}>
                                            <td>#{index+1}</td>
                                            <td>
                                                <div className="contentWithImg">
                                                    <span><img src={item.image} alt={item.name} /></span>
                                                    <span>{item.name}</span>
                                                </div>
                                            </td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.product_store}</td>
                                            <td>{dateStr(item.expiry_date)}</td>
                                        </tr>
                                    )
                                ))}
                            </>
                    </Table>
                </div>
            ):(
                <div style={{marginTop:"32px"}}>
                    <Table table_head={["ID", "Product name", "Size", "Quantity", "Store", "Expiry Date"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
                        tableSearch={<CardSearch value={searchNextText} onTextChange={(evt) => onSearch(evt)} />}
                        tableAction={<ExportData export_type={(val) => processExport(val, 'over')} />}
                        headerStyle={{marginBottom: "44px"}}
                        >
                            <>
                                {productNextList.map((item, index) => (
                                    (item.show || item.show === undefined) && (
                                        <tr key={index}>
                                            <td>#{index+1}</td>
                                            <td>
                                                <div className="contentWithImg">
                                                    <span><img src={item.image} alt={item.name} /></span>
                                                    <span>{item.name}</span>
                                                </div>
                                            </td>
                                            <td>{item.size}</td>
                                            <td>{item.quantity}</td>
                                            <td>{item.product_store}</td>
                                            <td>{dateStr(item.expiry_date)}</td>
                                        </tr>
                                    )
                                ))}
                            </>
                    </Table>
                </div>
            )}
        </>
    )
}

export default ProductExpirySubLevel;