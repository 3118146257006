import 'react-toastify/dist/ReactToastify.css';
import 'react-quill/dist/quill.snow.css';
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AddMessage, AddProduct, AddStaff, AddStore, AddVendor, AdminTicket, Dashboard, ErrorPage, Home, ImportProduct, Login, MessageList, MessageObjList, ProductDamaged, ProductDamagedSublevel, ProductExpiry, ProductExpirySubLevel, ProductList, SendMessage, StaffList, StaffProductDamage, StaffProductDamageSublevel, StaffProductExpiry, StaffProductExpirySublevel, StaffProductList, StaffProductListSublevel, StaffProductOrder, StaffResponseTicket, StaffStoreList, StaffStoreMenu, StaffTicket, StaffVendorList, StockOrder, StoreList, UserProfile, VendorList, VendorMenu, VendorProductDamage, VendorProductDamageSublevel, VendorProductExpiry, VendorProductExpirySublevel, VendorProductList, VendorProductSubList, VendorRaiseTicket, VendorStockOrder, VendorStoreList, VendorTicketList } from './pages';
import { Store } from './redux/store';
import { Provider } from 'react-redux';

function App() {
  return (
    <Provider store={Store}>
      <Router>
        <Routes>
          <Route
            caseSensitive
            index
            path={"/"}
            element={<Login />}
          />

          <Route
            caseSensitive
            path={"/:account_type/*"}
            element={<Home />}
          >
            <Route 
              caseSensitive
              path={"dashboard/"}
              element={<Dashboard />}
            />
            <Route 
              caseSensitive
              path={"product/"}
              element={<ProductList />}
            />
            <Route 
              caseSensitive
              path={"create_product/"}
              element={<AddProduct />}
            />
            <Route 
              caseSensitive
              path={"create_product/edit/:id/"}
              element={<AddProduct />}
            />
            <Route 
              caseSensitive
              path={"import_product/"}
              element={<ImportProduct />}
            />
            <Route 
              caseSensitive
              path={"expiring_product/"}
              element={<ProductExpiry />}
            />
            <Route 
              caseSensitive
              path={"expiring_product_sub/:child_id/"}
              element={<ProductExpirySubLevel />}
            />
            <Route 
              caseSensitive
              path={"damaged_product/"}
              element={<ProductDamaged />}
            />
            <Route 
              caseSensitive
              path={"damaged_product_child/:product_id"}
              element={<ProductDamagedSublevel />}
            />

            
            <Route 
                caseSensitive
                path={"vendor/"}
                element={<VendorList />}
            />
            <Route 
                caseSensitive
                path={"vendor/add/"}
                element={<AddVendor />}
            />
            <Route 
                caseSensitive
                path={"vendor/edit/:id/"}
                element={<AddVendor />}
            />


            <Route 
              caseSensitive
              path={"staff/"}
              element={<StaffList />}
            />
            <Route 
              caseSensitive
              path={"staff/add/"}
              element={<AddStaff />}
            />
            <Route 
              caseSensitive
              path={"staff/edit/:id/"}
              element={<AddStaff />}
            />


            <Route 
              caseSensitive
              path={"message/"}
              element={<MessageList />}
            />
            <Route 
              caseSensitive
              path={"message/add/"}
              element={<AddMessage />}
            />
            <Route 
              caseSensitive
              path={"message/reply/:id/"}
              element={<AddMessage />}
            />


            <Route 
              caseSensitive
              path={"ticket/"}
              element={<AdminTicket />}
            />

            <Route 
              caseSensitive
              path={"store/"}
              element={<StoreList />}
            />

            <Route 
              caseSensitive
              path={"store/add/"}
              element={<AddStore />}
            />

            <Route 
              caseSensitive
              path={"store/edit/:id/"}
              element={<AddStore />}
            />

            {/* Staff Pages */}
            <Route 
              caseSensitive
              path={"store_list/"}
              element={<StaffStoreList />}
            />
            <Route 
              caseSensitive
              path={"vendor_list/:id/"}
              element={<StaffVendorList />}
            />
            <Route 
              caseSensitive
              path={"store_menu/:id/:store_id/"}
              element={<StaffStoreMenu />}
            />
            <Route 
              caseSensitive
              path={"purchase_order/:id/:store_id/"}
              element={<StockOrder />}
            />
            <Route 
              caseSensitive
              path={"product_list/:id/:store_id/"}
              element={<StaffProductList />}
            />
            <Route 
              caseSensitive
              path={"product_sub_list/:id/:store_id/:order_id/"}
              element={<StaffProductListSublevel />}
            />
            <Route 
              caseSensitive
              path={"product_order/:id/:store_id/"}
              element={<StaffProductOrder />}
            />
            <Route 
              caseSensitive
              path={"product_order_edit/:id/:store_id/:order_id/:child_id/"}
              element={<StaffProductOrder />}
            />
            <Route 
              caseSensitive
              path={"product_expiry/:id/:store_id/"}
              element={<StaffProductExpiry />}
            />
            <Route 
              caseSensitive
              path={"product_expiry_sub/:id/:store_id/:child_id"}
              element={<StaffProductExpirySublevel />}
            />
            <Route 
              caseSensitive
              path={"product_damaged/:id/:store_id/"}
              element={<StaffProductDamage />}
            />
            <Route 
              caseSensitive
              path={"product_damaged_child/:id/:store_id/:product_id"}
              element={<StaffProductDamageSublevel />}
            />
            <Route 
              caseSensitive
              path={"message/list/"}
              element={<MessageObjList />}
            />
            <Route 
              caseSensitive
              path={"message/send/"}
              element={<SendMessage />}
            />
            <Route 
              caseSensitive
              path={"message/send/edit/:id/"}
              element={<SendMessage />}
            />
            <Route 
              caseSensitive
              path={"ticket/list/"}
              element={<StaffTicket />}
            />
            <Route 
              caseSensitive
              path={"ticket/edit/:id/"}
              element={<StaffResponseTicket />}
            />

            {/* Vendor Pages */}
            <Route 
              caseSensitive
              path={"vendor_store_list/"}
              element={<VendorStoreList />}
            />
            <Route 
              caseSensitive
              path={"vendor_menu/:id/"}
              element={<VendorMenu />}
            />
            <Route 
              caseSensitive
              path={"vendor_stock_order/:id/"}
              element={<VendorStockOrder />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_list/:id/"}
              element={<VendorProductList />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_sub_list/:store_id/:order_id/"}
              element={<VendorProductSubList />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_expiry/:id/"}
              element={<VendorProductExpiry />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_expiry_sub/:id/:child_id"}
              element={<VendorProductExpirySublevel />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_damaged/:id/"}
              element={<VendorProductDamage />}
            />
            <Route 
              caseSensitive
              path={"vendor_product_damaged_child/:id/:product_id"}
              element={<VendorProductDamageSublevel />}
            />

            <Route 
              caseSensitive
              path={"ticket/vendor_list/"}
              element={<VendorTicketList />}
            />
            
            <Route 
              caseSensitive
              path={"ticket/vendor_raise/"}
              element={<VendorRaiseTicket />}
            />

            <Route 
              caseSensitive
              path={"ticket/vendor_raise/edit/:id/"}
              element={<VendorRaiseTicket />}
            />

            <Route 
              caseSensitive
              path={"account/profile/"}
              element={<UserProfile />}
            />
          </Route>

          <Route 
            path="/404/"
            element={<ErrorPage />}
          />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
