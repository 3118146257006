import { ActionDropDown, CardSearch, ExportData, PageBodyHeader, Table } from "../../component";
import { useEffect, useState } from "react";
import { UrlTypes, generalObj } from "../../constant/object_types";
import { useNavigate, useOutletContext } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../redux";
import { fetchDamageProduct, fetchProductDmgListReport, fetchStoreList, fetchUserList } from "../../action/generalFunc";
import { toast } from "react-toastify";
import ReportAdminModal from "../../component/modal/ReportAdminModal";

const ProductDamaged = () => {
    const [productList, setProductList] = useState<generalObj[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [vendorList, setVendorList] = useState<generalObj[]>([
        {
            id: 0,
            label: 'All',
            value: 'all'
        }
    ]);
    const [storeList, setStoreList] = useState<generalObj[]>([
        {
            id: 0,
            label: 'All',
            value: 'all'
        }
    ]);
    const [openDamageModal, setOpenDamageModal] = useState<boolean>(false);
    const [exportTypes, setExportTypes] = useState<string>('');

    const {setModalLoading}:{setModalLoading:Function} = useOutletContext();
    const account = useSelector((state:RootState) => state.account);
    const navigate = useNavigate();
     
    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        (async () => {
            let dmg_result = await fetchDamageProduct(account.token)
            if (!dmg_result) {
                navigate(UrlTypes.ADMIN_DASHBOARD);
            }
            setProductList(dmg_result.products);
            let result = await fetchUserList(account.token, "vendor");
            if (result) {
                let user_result:generalObj[] = [
                    {
                        id: 0,
                        label: 'All',
                        value: ''
                    }
                ];
                result.data.users.forEach((element:generalObj) => {
                    user_result.push({
                        id: element.id,
                        label: element.username,
                        value: element.id
                    })
                });
                setVendorList(user_result);
            }
            let sResult = await fetchStoreList(account.token);
            if (sResult) {
                let store_result:generalObj[] = [
                    {
                        id: 0,
                        label: 'All',
                        value: ''
                    }
                ];
                sResult.data.store.forEach((element:generalObj) => {
                    store_result.push({
                        id: element.id,
                        label: `${element.name} - ${element.organization}`,
                        value: element.id
                    });
                });
                setStoreList(store_result);
            }
            setModalLoading(false)
        })();
    }, [account, setModalLoading, navigate]);

    const processExport = (export_type:string) => {
        setExportTypes(export_type);
        setOpenDamageModal(true);
    }

    const onSearch = (val:string) => {
        setProductList((prevProductList) => {
            return (
                prevProductList.filter((item) => {
                    if (item.name.toLowerCase().includes(val.toLowerCase()) || item.order_id.toLowerCase().includes(val.toLowerCase())  || val.toLowerCase().includes(item.damaged) || val.toLowerCase().includes(item.returns) || val.toLowerCase().includes(item.return_date) || item.product_store.toLowerCase().includes(val.toLowerCase())) {
                        item.show = true;
                    }else {
                        item.show = false;
                    }
                    return item;
                })
            )
        })
        setSearchText(val);
    }

    const generateReport = async (exportType:string, paramObj:generalObj) => {
        if (!exportType || !account || !account.isAuthenticated) return;

        setModalLoading(true);
        let param:generalObj = {
            type: exportType,
        };

        if (paramObj.vendor.value !== '') {
            param.vendor = paramObj.vendor.value;
        }

        if (paramObj.store.value !== '') {
            param.store = paramObj.store.value;
        }
        
        let result = await fetchProductDmgListReport(account.token, param);

        if (result && typeof(result) === "object") {
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `product_damage_parent_admin.${result.data.type.split("/")[1]}`);
            const href = URL.createObjectURL(result.data);
            aElement.href = href;
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);
            toast("Report generated");
        }
        setModalLoading(false);
    }

    return (
        <>
            <PageBodyHeader title="Damages and Returns" desc="List of Damaged and Returned Products" />

            <Table table_head={["ID", "Product name", "Size", "Quantity Damaged",  "Quantity Returned", "Date", "Store", "Vendor", "Action"]} th_class="white" color={"#1159AB"} thColor={"#1159AB"} 
            tableSearch={<CardSearch value={searchText} onTextChange={(evt) => onSearch(evt)} />}
            tableAction={<ExportData export_type={(val) => processExport(val)} />}
            headerStyle={{marginBottom: "44px"}}
            >
                <>
                    {productList.map((item, index) => (
                        (item.show || item.show === undefined) && (
                            <tr key={index}>
                                <td>#{index+1}</td>
                                <td>
                                    <div className="contentWithImg">
                                        <span><img src={item.image} alt={item.name} /></span>
                                        <span>{item.name}</span>
                                    </div>
                                </td>
                                <td>{item.size}</td>
                                <td>{item.damaged}</td>
                                <td>{item.returns}</td>
                                <td>{item.return_date.map((rItem:string, index:number) => (
                                    <div key={index}>{rItem}</div>
                                ))}</td>
                                <td>{item.product_store}</td>
                                <td>{item.product_vendor}</td>
                                <td>
                                    <div className="edit__delete_action">
                                        <ActionDropDown>
                                            <ul>
                                                <li>
                                                    <button onClick={() => navigate(`${UrlTypes.ADMIN_PRODUCT_DAMAGED_CHILD}${item.order_id}`)}>View</button>
                                                </li>
                                            </ul>
                                        </ActionDropDown>
                                    </div>
                                </td>
                            </tr>
                        )
                    ))}
                </>
            </Table>
            <ReportAdminModal visible={openDamageModal} storeList={storeList} vendorList={vendorList} modalClose={() => setOpenDamageModal(false)} onAdded={(val) => generateReport(exportTypes, val)} />
        </>
    )
}

export default ProductDamaged;