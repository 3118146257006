import { useNavigate } from "react-router-dom";
import { MailSvg, PasswordSvg } from "../style/iconStyle";
import { useEffect, useState } from "react";
import { ModalLoader } from "../component";
import { UrlTypes, generalObj } from "../constant/object_types";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState, actionCreator } from "../redux";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState<generalObj>({
        email: "",
        password: ""
    })
    const [modalLoading, setModalLoading] = useState<boolean>(false);
    const account = useSelector((state:RootState) => state.account);
    const dispatch = useDispatch();
    const { login, logout } = bindActionCreators(actionCreator, dispatch);

    useEffect(() => {
        if (!account || !account.isAuthenticated) return;
        if (account.role === 'Admin') {
            navigate(UrlTypes.ADMIN_DASHBOARD);
        }else if (account.role === 'Staff') {
            navigate(UrlTypes.STAFF_DASHBOARD);
        }else if (account.role === 'Vendor') {
            navigate(UrlTypes.VENDOR_DASHBOARD);
        }else {
            logout();
            toast("You have no specified role");
        }
    })

    const loginUser = async () => {
        if (!formData.email || !formData.password) {
            toast("Email & password are required");
            return;
        }
        setModalLoading(true);
        await login(formData.email, formData.password)
        
        setModalLoading(false)
    }

    return (
        <>
            <div className="login__page">
                <div className="login__logo">
                    <img src={require("../assets/img/logo.png")} alt="logo" />
                </div>
                <div className="login_title">
                    <h1>Timpre Merchandising And General Trading Ltd</h1>
                </div>
                
                <div className="login__body">
                    <div>
                        <h2>Login</h2>
                        <div className="login__input">
                            <span><MailSvg /></span>
                            <input type="text" placeholder="Email Address" value={formData.email} onChange={(evt) => setFormData({...formData, email: evt.target.value})} />
                        </div>
                        <div className="login__input">
                            <span><PasswordSvg /></span>
                            <input type="password" placeholder="Password" value={formData.password} onChange={(evt) => setFormData({...formData, password: evt.target.value})} />
                        </div>

                        <div className="login__button" onClick={() => loginUser()}>
                            <button>Login</button>
                        </div>
                    </div>
                </div>
                <img src={require("../assets/img/arc1.png")} className="log__arc arc1" alt="arc" />
                <img src={require("../assets/img/arc2.png")} className="log__arc arc2" alt="arc" />
                <img src={require("../assets/img/arc3.png")} className="log__arc arc3" alt="arc" />
                <img src={require("../assets/img/arc4.png")} className="log__arc arc4" alt="arc" />
            </div>
            <ModalLoader visible={modalLoading} />
            <ToastContainer theme="dark" />
        </>
    )
}

export default Login;